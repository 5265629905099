import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  CheckinEntryStatus,
  useGetCheckinEntryDetailLazyQuery,
  useGetExitingDirectVisitorsLazyQuery,
} from '~/graphql';
import { getMe, getNode } from '~/graphql/utility';
import { checkinEntryProfileDrawerState } from '~/state/reception/atoms';

export const useFetchCheckinEntry = () => {
  const { isOpen, checkinEntryId } = useRecoilValue(checkinEntryProfileDrawerState);

  const [getDirectVisitors, { data: visitorData }] = useGetExitingDirectVisitorsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getCheckin, { data, loading }] = useGetCheckinEntryDetailLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: async (_data) => {
      const _checkinEntry = getNode(_data, 'CheckinEntry');
      // 完了ステータスの場合のみ、類似の仮患者が存在するか確認する
      if (_checkinEntry && _checkinEntry.status === CheckinEntryStatus.Finished) {
        const searchWord = `${_checkinEntry.phoneticFamilyName} ${_checkinEntry.phoneticGivenName}`;
        await getDirectVisitors({
          variables: { searchWord: searchWord, phoneNumber: _checkinEntry.phoneNumber },
        });
      }
    },
  });

  const checkinEntry = getNode(data, 'CheckinEntry');
  const exitingDirectVisitor = useMemo(() => {
    if (!visitorData) return null;
    const visitors = getMe(visitorData)?.organization.directVisitors.nodes || [];
    const visitor = visitors.find(
      (v) =>
        v.phoneticGivenName === checkinEntry?.phoneticGivenName &&
        v.phoneticFamilyName === checkinEntry?.phoneticFamilyName &&
        v.mobilePhone === checkinEntry?.phoneNumber,
    );
    return visitor || null;
  }, [
    checkinEntry?.phoneNumber,
    checkinEntry?.phoneticFamilyName,
    checkinEntry?.phoneticGivenName,
    visitorData,
  ]);

  useEffect(() => {
    if (isOpen && checkinEntryId) {
      getCheckin({
        variables: {
          id: checkinEntryId,
        },
      });
    }
  }, [getCheckin, isOpen, checkinEntryId]);

  return { checkinEntry, exitingDirectVisitor, loading };
};
