import { useEffect } from 'react';

import { useGetWebVisitDetailLazyQuery, WebVisitorAppointmentDetailFragment } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';

type Appointment = {
  loading: boolean;
  appointment?: WebVisitorAppointmentDetailFragment;
};

export const useFetchAppointment = (isOpen: boolean, appointmentId: string | null): Appointment => {
  const [event, clear] = useSubscriptions('Appointment', ['updated']);

  const [getWebVisit, { loading, data }] = useGetWebVisitDetailLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (event) {
        clear();
      }
    },
  });
  const appointment = getNode(data, 'Appointment');

  useEffect(() => {
    if (isOpen && appointmentId) {
      getWebVisit({
        variables: {
          id: appointmentId,
        },
      });
    }
  }, [getWebVisit, isOpen, appointmentId]);

  useEffect(() => {
    if (event?.actionName === 'updated' && event.typeId === appointmentId) {
      getWebVisit({ variables: { id: appointmentId } });
    }
  }, [appointmentId, event?.actionName, event?.typeId, getWebVisit]);

  return { loading, appointment };
};
