import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Box, Button, DateLabel, EntryList, Flex, Icon } from '~/components/blocks';
import { AppointmentStatusLabel, MemoField, ReceptionTypeIcon } from '~/components/partials';
import { PlanFeatureCode, VisitDetailFragment } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { guestPatientTabState } from '~/state/reception/atoms';
import { theme } from '~/styles/theme';
import { Label } from '~/utils/label';

import { EditGuestPatientProfile } from './EditGuestPatientProfile';
import { GuestPatientProfile } from './GuestPatientProfile';
import { PrescriptionCollapse } from './PrescriptionCollapse';
import { ReadOnlyMemo } from './ReadOnlyMemo';
import { useUpdateMemo } from './use-update-memo';

type Props = {
  visit: VisitDetailFragment;
};

export const ProfilePane = React.memo((props: Props) => {
  const { visit } = props;
  const { editableProfile } = useRecoilValue(guestPatientTabState);

  const { hasEnabledFeature } = usePlanFeature(PlanFeatureCode.Followup);
  const { updating, latestMemo, update } = useUpdateMemo(visit.id);
  const directVisitor = visit.directVisitor;

  const handleChangeMemo = useCallback(
    (memo: string) => update(visit.id, memo),
    [update, visit.id],
  );

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(guestPatientTabState, (_state) => ({ ..._state, editableProfile: true }));
      },
    [],
  );

  return (
    <Box position="relative" height="100%" overflow="auto">
      {editableProfile ? (
        <EditGuestPatientProfile guestPatient={directVisitor} />
      ) : (
        <Box padding={theme.space.l} overflow="auto">
          <Flex alignItems="center" marginY={theme.space.m} justifyContent="space-between">
            <Flex justifyContent="center" alignItems="center">
              <ReceptionTypeIcon size="xxxl" />
            </Flex>
            <DateLabel orientation="vertical">
              <DateLabel.Day>{Label.YYYYMMDDja(visit.createdAt)}</DateLabel.Day>
              <DateLabel.Time>{Label.HHMM(visit.createdAt)}</DateLabel.Time>
            </DateLabel>
            <Box marginLeft="auto">
              <AppointmentStatusLabel status="finished" />
            </Box>
          </Flex>
          <PrescriptionCollapse />
          <Box marginTop={theme.space.l}>
            <GuestPatientProfile guestPatient={directVisitor} />
            <Button wide="fill" size="s" marginTop={theme.space.m} onClick={handleClick}>
              <Icon icon="edit" size="s" />
              編集
            </Button>
          </Box>
          <EntryList marginTop={theme.space.l}>
            <EntryList.Head>メモ</EntryList.Head>
            <EntryList.Body>
              {hasEnabledFeature ? (
                <MemoField
                  disabled={updating}
                  value={latestMemo.mutationCalled ? latestMemo.memo : visit.description || ''}
                  onChange={handleChangeMemo}
                />
              ) : (
                <ReadOnlyMemo description={visit.description} />
              )}
            </EntryList.Body>
          </EntryList>
        </Box>
      )}
    </Box>
  );
});

ProfilePane.displayName = 'ProfilePane';
