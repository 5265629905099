import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Button, Flex, Text } from '~/components/blocks';

type Props = {
  error: string;
  onClick: () => void;
};

const Root = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.default,
    borderRadius: theme.radii.default,
    boxShadow: theme.shadows.default,
    color: theme.colors.text.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    position: 'absolute',
    width: '350px',
    height: '280px',
    transform: 'translate(-50%, -50%)',
    padding: `${theme.space.xxl} ${theme.space.l}`,
    zIndex: 10,
  }),
);

export const FailureResult = React.memo((props: Props) => {
  return (
    <Root>
      <Text fontWeight="bold" size="m">
        QRコードの読み取りに失敗しました
      </Text>
      <Flex alignItems="center">
        <Text whiteSpace="pre-wrap" size="m">
          {props.error}
        </Text>
      </Flex>
      <Button use="base" wide="fill" onClick={props.onClick}>
        再読み取り
      </Button>
    </Root>
  );
});

FailureResult.displayName = 'FailureResult';
