import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';

import { Flex, Icon, Name, Radio, Text } from '~/components/blocks';
import { CheckinEntryItemFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  disabled: boolean;
  radioName?: string;
  radioValue: string | null;
  checkinEntry: CheckinEntryItemFragment;
  onClick: (directVisitorId: string) => void;
};

const Root = styled(Flex)<{ disabled?: boolean }>(({ theme, disabled }) =>
  css({
    alignItems: 'center',
    paddingLeft: theme.space.m,
    paddingRight: theme.space.m,
    ...(disabled && {
      opacity: 0.6,
      cursor: 'not-allowed',
    }),
  }),
);

export const CheckinEntry = React.memo((props: Props) => {
  const { disabled, radioName, checkinEntry, radioValue, onClick } = props;
  const theme = useTheme();

  const handleClick = useCallback(() => {
    onClick(checkinEntry.id);
  }, [checkinEntry.id, onClick]);

  const enteredAtText = useMemo(() => {
    const enteredAt = checkinEntry.enteredAt;
    return `${Label.MMDDja(enteredAt)} ${Label.HHMM(enteredAt)}`;
  }, [checkinEntry]);

  return (
    <Root disabled={disabled} onClick={disabled ? undefined : handleClick}>
      <Radio
        disabled={disabled}
        name={radioName ?? 'candidate'}
        value={checkinEntry.id}
        checked={radioValue === checkinEntry.id}
        onChange={handleClick}
      />
      <Flex marginLeft={theme.space.m} alignItems="center">
        <Icon icon="patient-checkin" size="xxxl" color="black" />
        <Flex marginLeft={theme.space.m} flexDirection="column">
          <Name
            size="s"
            familyName={checkinEntry.familyName}
            givenName={checkinEntry.givenName}
            phoneticFamilyName={checkinEntry.phoneticFamilyName}
            phoneticGivenName={checkinEntry.phoneticGivenName}
          />
          <Text size="xs" marginTop={theme.space.s}>
            {checkinEntry.phoneNumber}
          </Text>
          <Text size="xs">{`${enteredAtText}にチェックイン`}</Text>
        </Flex>
      </Flex>
    </Root>
  );
});

CheckinEntry.displayName = 'CheckinEntry';
