import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetB2cloudSettingForDeliveryLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { b2CloudDownloadModalState } from '~/state/reception/atoms';

export const useFetchB2cloudSetting = () => {
  const { isOpen } = useRecoilValue(b2CloudDownloadModalState);

  const [getB2cloudSetting, { data, loading }] = useGetB2cloudSettingForDeliveryLazyQuery();

  const isEnabled = data ? !!getMe(data)?.organization.b2cloudSetting?.data.enable : false;

  useEffect(() => {
    if (isOpen) {
      getB2cloudSetting();
    }
  }, [getB2cloudSetting, isOpen]);

  return { loading, enabled: isEnabled };
};
