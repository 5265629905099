import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Button, Flex } from '~/components/blocks';
import { CheckinEntryDetailFragment, CheckinEntryStatus } from '~/graphql';
import {
  checkinFinishConfirmModalModalState,
  checkinNotifyConfirmModalState,
} from '~/state/reception/atoms';

import { useFinishCheckinEntry } from '../use-finish-checkin_entry';

type Props = {
  checkinEntry: CheckinEntryDetailFragment;
};

const ActionButton = styled(Button)(() =>
  css({
    flexBasis: '70%',
  }),
);

const FinishButton = styled(Button)<{ full: boolean }>(({ full }) =>
  css({
    flexBasis: full ? '100%' : '30%',
  }),
);

export const BookedButtons = (props: Props) => {
  const theme = useTheme();
  const { checkinEntry } = props;
  const enableNotification = checkinEntry.notificationsCount < 2;
  const setFinishState = useSetRecoilState(checkinFinishConfirmModalModalState);
  const setNotifyState = useSetRecoilState(checkinNotifyConfirmModalState);
  const { finish, finishing } = useFinishCheckinEntry(checkinEntry.id);

  const handleFinishClick = useCallback(async () => {
    if (checkinEntry.status === CheckinEntryStatus.Entered) {
      setFinishState((_state) => ({ ..._state, isOpen: true, checkinEntryId: checkinEntry.id }));
    } else {
      await finish();
    }
  }, [checkinEntry.id, checkinEntry.status, finish, setFinishState]);

  const handleNotificationClick = useCallback(async () => {
    setNotifyState((_state) => ({ ..._state, isOpen: true, checkinEntryId: checkinEntry.id }));
  }, [checkinEntry.id, setNotifyState]);

  return (
    <Flex alignItems="center" justifyContent="center">
      <FinishButton
        use="base"
        wide="fill"
        size="l"
        onClick={handleFinishClick}
        full={!enableNotification}
        loading={finishing}
      >
        完了
      </FinishButton>
      {enableNotification && (
        <ActionButton
          use="primary"
          wide="fill"
          size="l"
          ml={theme.space.m}
          onClick={handleNotificationClick}
        >
          {checkinEntry.notificationsCount === 0 ? '準備完了通知' : '再通知'}
        </ActionButton>
      )}
    </Flex>
  );
};
