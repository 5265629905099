import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import {
  Alert,
  Button,
  Drawer,
  Flex,
  Grid,
  Header,
  Icon,
  List,
  Pagination,
  ScrollBox,
} from '~/components/blocks';
import { useScrollToTop } from '~/hooks/use-scroll-to-top';
import { newReceptionDrawerState } from '~/state/reception/atoms';
import { NewReceptionPanelMap } from '~/state/reception/types';

import { DirectVisitorCandidate } from '../DirectVisitorCandidate';
import { SearchDirectVisitors } from '../SearchDirectVisitors';
import { useCreateVisit } from '../use-create-visit';
import { useFetchDirectVisitorCandidates } from '../use-fetch-direct_visitor_candidates';
import { CheckinEntryItem } from './CheckinEntryItem';

const ResultList = styled(List)(({ theme }) =>
  css({ borderTop: 'none', marginTop: theme.space.m }),
);

export const SelectReceptionDirectVisitorPanel = () => {
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const [gridRef, scrollToTop] = useScrollToTop<HTMLDivElement>();

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [valueError, setValueError] = useState<string | null>(null);
  const { page, totalPage } = useRecoilValue(newReceptionDrawerState);

  const { loading, directVisitorCandidates } = useFetchDirectVisitorCandidates();
  const { creating, error, create } = useCreateVisit();

  const selectedCandidate = useMemo(() => {
    return directVisitorCandidates.find(({ id }) => id === selectedValue);
  }, [directVisitorCandidates, selectedValue]);

  const handleChangePage = useRecoilCallback(
    ({ set }) =>
      (_page: number) =>
        set(newReceptionDrawerState, (_state) => ({
          ..._state,
          page: _page,
        })),
    [],
  );
  const handleClickBack = useRecoilCallback(
    ({ set }) =>
      () =>
        set(newReceptionDrawerState, (_state) => ({
          ..._state,
          currentPanel: NewReceptionPanelMap.input,
          searchWord: '',
        })),
    [],
  );
  const handleClickCandidate = useCallback(
    (candidateId: string) => setSelectedValue(candidateId),
    [],
  );
  const handleClickFamily = useRecoilCallback(
    ({ set }) =>
      () => {
        if (selectedValue === null || !selectedCandidate) {
          setValueError('患者を選択してください');
          scrollToTop();
          return;
        }

        setValueError(null);
        set(newReceptionDrawerState, (_state) => ({
          ..._state,
          currentPanel: NewReceptionPanelMap.inputFamily,
          selectedCandidate,
          qrCodePatient: null,
        }));
      },
    [scrollToTop, selectedCandidate, selectedValue],
  );

  const handleClickReception = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (selectedValue === null || !selectedCandidate) {
          setValueError('患者を選択してください');
          scrollToTop();
          return;
        }
        setValueError(null);

        if (selectedCandidate.__typename === 'CheckinEntry') {
          set(newReceptionDrawerState, (_state) => ({
            ..._state,
            currentPanel: NewReceptionPanelMap.checkin,
            selectedCandidate,
            qrCodePatient: null,
          }));
          return;
        }

        try {
          await create(selectedValue);
        } catch {
          scrollToTop();
        }
      },
    [create, scrollToTop, selectedCandidate, selectedValue],
  );

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="auto">
      <Header attached="drawer">新患受付</Header>
      <Grid
        ref={gridRef}
        overflow="auto"
        height="100%"
        gridTemplateRows="min-content 1fr min-content min-content"
        paddingX={theme.space.l}
      >
        <SearchDirectVisitors />
        <ScrollBox ref={scrollRef} loaderLogoSize="half" loading={loading}>
          {error && <Alert marginTop={theme.space.m}>{error}</Alert>}
          {valueError && <Alert marginTop={theme.space.m}>{valueError}</Alert>}
          {directVisitorCandidates.length === 0 && !loading ? (
            <Alert status="info" marginTop={theme.space.l}>
              該当する患者は見つかりませんでした
            </Alert>
          ) : (
            <ResultList selectable={!loading && !creating}>
              {directVisitorCandidates.map((candidate, idx) => (
                <List.Item key={idx}>
                  {candidate.__typename === 'DirectVisitor' && (
                    <DirectVisitorCandidate
                      disabled={loading}
                      candidate={candidate}
                      radioValue={selectedValue}
                      onClick={handleClickCandidate}
                    />
                  )}
                  {candidate.__typename === 'CheckinEntry' && (
                    <CheckinEntryItem
                      disabled={loading}
                      checkinEntry={candidate}
                      radioValue={selectedValue}
                      onClick={handleClickCandidate}
                    />
                  )}
                </List.Item>
              ))}
            </ResultList>
          )}
        </ScrollBox>
        {directVisitorCandidates.length > 0 && (
          <Flex justifyContent="center" marginTop={theme.space.m}>
            <Pagination currentPage={page} totalPage={totalPage} onChange={handleChangePage} />
          </Flex>
        )}
        <Flex justifyContent="flex-start" marginBottom={theme.space.m}>
          <Button
            size="s"
            use="white"
            marginTop={theme.space.l}
            disabled={creating}
            onClick={handleClickBack}
          >
            <Icon icon="back-line" size="s" />
            戻る
          </Button>
        </Flex>
      </Grid>
      <Drawer.Footer>
        <Flex justifyContent="space-between">
          <Button wide="half" disabled={creating} onClick={handleClickFamily}>
            家族を追加
          </Button>
          <Button use="base" wide="half" loading={creating} onClick={handleClickReception}>
            受付
          </Button>
        </Flex>
      </Drawer.Footer>
    </Grid>
  );
};
