import { max } from 'date-fns';
import React from 'react';

import { Flex, Name, Table } from '~/components/blocks';
import { BookmarkPatientListFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  patient: BookmarkPatientListFragment;
};

export const BookmarkPatient = React.memo((props: Props) => {
  const { patient } = props;

  if (!patient.bookmark) {
    return null;
  }

  const appointmentUpdatedAt = patient.appointments.nodes[0]?.updatedAt;
  const draftAppointmentUpdatedAt = patient.draftAppointments.nodes[0]?.updatedAt;

  const latestAppointmentUpdatedAt =
    appointmentUpdatedAt && draftAppointmentUpdatedAt
      ? max([new Date(appointmentUpdatedAt), new Date(draftAppointmentUpdatedAt)])
      : appointmentUpdatedAt
        ? appointmentUpdatedAt
        : draftAppointmentUpdatedAt;

  const totalAppointmentCount =
    patient.appointments.nodesCount + patient.draftAppointments.nodesCount;

  const latestAppointmentUpdatedAtLabel =
    totalAppointmentCount > 0 ? Label.YYYYMMDDja(latestAppointmentUpdatedAt) : '-';

  return (
    <Table.Tr>
      <Table.Td>{Label.YYYYMMDDja(patient.bookmark.updatedAt)}</Table.Td>
      <Table.Td>
        <Flex alignItems="center">
          <Name
            familyName={patient.familyName}
            givenName={patient.givenName}
            phoneticFamilyName={patient.phoneticFamilyName}
            phoneticGivenName={patient.phoneticGivenName}
            size="s"
          />
        </Flex>
      </Table.Td>
      <Table.Td textAlign="right">{totalAppointmentCount}</Table.Td>
      <Table.Td>{latestAppointmentUpdatedAtLabel}</Table.Td>
    </Table.Tr>
  );
});

BookmarkPatient.displayName = 'BookmarkPatient';
