import { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { useScrollToTop } from '~/hooks/use-scroll-to-top';
import { newReceptionDrawerState } from '~/state/reception/atoms';

import { useCreateDirectVisitor } from '../use-create-direct_visitor';
import { useCreateVisit } from '../use-create-visit';

export const useCreateOrVisitDirectVisitor = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [boxRef, scrollToTop] = useScrollToTop<HTMLDivElement>();
  const { create: createVisit } = useCreateVisit();
  const { create: createDirectVisitor } = useCreateDirectVisitor();

  const createOrVisit = useRecoilCallback(
    ({ reset, snapshot }) =>
      async (value: string) => {
        setIsCreating(true);
        setError(null);

        try {
          if (value === 'new') {
            const _state = await snapshot.getPromise(newReceptionDrawerState);
            if (_state.inputValue) {
              await createDirectVisitor(_state.inputValue);
            }
          } else {
            await createVisit(value);
          }
          reset(newReceptionDrawerState);
        } catch (_error) {
          scrollToTop();
          setError(_error?.message || 'エラーが発生しました');
        }
        setIsCreating(false);
      },
    [createDirectVisitor, createVisit, scrollToTop],
  );

  return {
    creating: isCreating,
    error,
    boxRef,
    createOrVisit,
  };
};
