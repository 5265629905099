import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Flex, Icon, TextField } from '~/components/blocks';
import { receptionPageInfoState } from '~/state/reception/atoms';
import { throttle } from '~/utils/throttle';

const Root = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    backgroundColor: theme.colors.background.default,
    padding: `0 ${theme.space.m}`,
    borderRadius: theme.radii.default,
    border: theme.borders.default,
  }),
);

const throttleInput = throttle(500);

export const SearchBox = () => {
  const [searchWord, setSearchWord] = useState('');
  const state = useRecoilValue(receptionPageInfoState);
  const handleChangeName = useRecoilCallback(
    ({ set }) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const patientName = e.target.value;

        setSearchWord(patientName);
        throttleInput(() => {
          set(receptionPageInfoState, (_state) => ({ ..._state, patientName, page: 1 }));
        });
      },
  );

  useEffect(() => {
    setSearchWord(state.patientName || '');
  }, [state.patientName]);

  return (
    <Root>
      <Icon size="l" icon="search" />
      <TextField
        appearance="search"
        placeholder="患者名で検索"
        value={searchWord || ''}
        onChange={handleChangeName}
      />
    </Root>
  );
};
