export const throttle = (wait: number) => {
  let timerId: number;

  return (callback: () => void) => {
    clearTimeout(timerId);

    timerId = window.setTimeout(() => {
      callback();
    }, wait);
  };
};
