import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Icon, Tag, Text } from '~/components/blocks';

const CustomTag = styled(Tag)(({ theme }) =>
  css({
    alignItems: 'center',
    border: theme.borders.transparent,
    background: theme.colors.background.bg,
    display: 'inline-flex',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
  }),
);

type Props = {
  fontSize?: 's' | 'm';
};

export const ClinicsAppTag = React.memo((props: Props) => {
  return (
    <CustomTag>
      <Icon icon="smartphone" color="navy" size="l" />
      <Text size={props.fontSize} color="navy">
        CLINICS会員
      </Text>
    </CustomTag>
  );
});

ClinicsAppTag.displayName = 'ClinicsAppTag';
