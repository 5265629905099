import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Box } from '~/components/blocks';
import { tracingReportPageInfoState } from '~/state/partials/tracingreport_panel/atoms';
import { guestPatientFileState } from '~/state/reception/atoms';

import { TracingReport } from './TracingReport';

type Props = {
  guestPatientId: string;
};
export const FilePane = (props: Props) => {
  const theme = useTheme();
  const { guestPatientId } = props;
  const setPageInfo = useSetRecoilState(tracingReportPageInfoState);
  const [guestPatientFile, setGuestPatientFile] = useRecoilState(guestPatientFileState);

  useEffect(() => {
    if (guestPatientFile.guestPatientId !== guestPatientId) {
      setPageInfo((_state) => ({
        ..._state,
        target: 'GuestPatient',
        targetId: guestPatientFile.guestPatientId,
        page: 1,
        totalPage: 1,
      }));

      setGuestPatientFile((_state) => ({
        ..._state,
        guestPatientId,
      }));
    }
  }, [guestPatientId, guestPatientFile.guestPatientId, setPageInfo, setGuestPatientFile]);

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      <TracingReport guestPatientId={props.guestPatientId} />
    </Box>
  );
};
