import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Modal } from '~/components/blocks';
import { b2CloudDownloadModalState } from '~/state/reception/atoms';

import { AppointmentList } from './AppointmentList';
import { Footer } from './Footer';
import { useFetchAppointments } from './use-fetch-appointments';
import { useFetchB2cloudSetting } from './use-fetch-b2cloud_setting';

export const B2CloudDownloadModal = () => {
  const { isOpen, error } = useRecoilValue(b2CloudDownloadModalState);

  const { loading: isFetchingAppointments, appointments } = useFetchAppointments();
  const { loading: isFetchingSetting, enabled } = useFetchB2cloudSetting();

  const loading = isFetchingAppointments || isFetchingSetting;
  const notFound = !loading && appointments.length === 0;

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(b2CloudDownloadModalState),
    [],
  );

  return (
    <Modal size="l" open={isOpen} onClose={handleClose}>
      <Modal.Header>ヤマト運輸 B2クラウドCSVダウンロード</Modal.Header>
      <Modal.Body>
        {error && (
          <Alert status="error" whiteSpace="pre-wrap">
            {error}
          </Alert>
        )}
        <AppointmentList notFound={notFound} loading={loading} appointments={appointments} />
      </Modal.Body>
      <Modal.Footer>
        <Footer isEnabled={enabled} notFound={notFound} />
      </Modal.Footer>
    </Modal>
  );
};
