import { useRecoilCallback } from 'recoil';

import { DraftAppointmentCancellationReason, useCancelDraftAppointmentMutation } from '~/graphql';
import { draftAppointmentPatientProfileDrawerState } from '~/state/reception/atoms';

export const useCancelDraftAppointment = (draftAppointmentId: string) => {
  const [cancel, { loading }] = useCancelDraftAppointmentMutation();

  const handleCancel = useRecoilCallback(
    ({ set }) =>
      async (reason: DraftAppointmentCancellationReason) => {
        try {
          await cancel({
            variables: {
              input: {
                draftAppointmentId,
                reason,
              },
            },
          });
        } catch (_error) {
          set(draftAppointmentPatientProfileDrawerState, (_state) => ({
            ..._state,
            error: _error?.message || 'エラーが発生しました',
          }));
        }
      },
    [draftAppointmentId, cancel],
  );

  return {
    isLoading: loading,
    handleCancel,
  };
};
