import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { useGetDirectVisitorCandidatesLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { newReceptionDrawerState } from '~/state/reception/atoms';

export const useFetchDirectVisitorCandidates = () => {
  const [{ inputValue, searchWord, page, perPage }, setState] =
    useRecoilState(newReceptionDrawerState);

  const [getDirectVisitorCandidates, { called, loading, data }] =
    useGetDirectVisitorCandidatesLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: (_result) => {
        const totalPage = getMe(_result)?.organization.directVisitorCandidates.pagesCount ?? 1;
        setState((_state) => ({ ..._state, totalPage }));
      },
    });
  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const directVisitorCandidates = currentData
    ? getMe(currentData)?.organization.directVisitorCandidates.nodes ?? []
    : [];

  useEffect(() => {
    if (inputValue?.mobilePhone || searchWord.length > 0)
      getDirectVisitorCandidates({
        variables: {
          page,
          perPage,
          searchWord,
          phoneNumber: inputValue?.mobilePhone ?? null,
        },
      });
  }, [getDirectVisitorCandidates, inputValue?.mobilePhone, page, perPage, searchWord]);

  return {
    loading: !called || loading,
    directVisitorCandidates,
  };
};
