import { useRecoilCallback } from 'recoil';

import { useFinishDraftAppointmentMutation } from '~/graphql';
import { finishDraftAppointmentModalState } from '~/state/reception/atoms';

type Props = {
  draftAppointmentId: string;
};

export const useFinishDraftAppointment = (props: Props) => {
  const [finish, { error, loading }] = useFinishDraftAppointmentMutation();

  const handleFinish = useRecoilCallback(
    ({ reset }) =>
      async () => {
        try {
          await finish({
            variables: {
              input: {
                draftAppointmentId: props.draftAppointmentId,
              },
            },
          });

          reset(finishDraftAppointmentModalState);
        } catch {
          // 何もしない
        }
      },
    [props.draftAppointmentId, finish],
  );

  return {
    loading,
    error,
    handleFinish,
  };
};
