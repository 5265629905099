import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect } from 'react';
import { useRecoilCallback, useResetRecoilState, useSetRecoilState } from 'recoil';

import { Button, Flex } from '~/components/blocks';
import { CancelPane } from '~/components/partials';
import { WebVisitorAppointmentDetailFragment } from '~/graphql';
import { cancelPane } from '~/state/partials/cancel_pane/atoms';
import {
  webVisitorChargeModalState,
  webVisitorNotificationConfirmModalState,
  webVisitorNotificationCongestionModalState,
} from '~/state/reception/atoms';

import { useCancelAppointment } from './use-cancel-appointment';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
};

const FinishButton = styled(Button)<{ isSentCongestionNotification: boolean }>(
  ({ isSentCongestionNotification }) =>
    css({
      flexBasis: isSentCongestionNotification ? '30%' : '50%',
    }),
);

const CongestionButton = styled(Button)(() =>
  css({
    flexBasis: '50%',
  }),
);

export const BookedButtons = (props: Props) => {
  const theme = useTheme();
  const isSentCongestionNotification = !!props.appointment.congestionNotificationSentAt;

  const { canceling, handleCancel } = useCancelAppointment(props.appointment.id);

  const setChargeModalState = useSetRecoilState(webVisitorChargeModalState);
  const setNotificationConfirmState = useSetRecoilState(webVisitorNotificationConfirmModalState);
  const resetCancelPane = useResetRecoilState(cancelPane);

  // 準備できたよ通知
  // モーダルで準備完了通知送信を確定したあと、指導待ち -> 未会計 （telemedicine: false）
  const handleSendNotification = useCallback(() => {
    setNotificationConfirmState({
      isOpen: true,
      appointmentId: props.appointment.id,
      webVisitorId: props.appointment.webVisitor?.id as string,
    });
  }, [props.appointment.id, props.appointment.webVisitor?.id, setNotificationConfirmState]);

  const handleCongestionClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(webVisitorNotificationCongestionModalState, {
          isOpen: true,
          appointmentId: props.appointment.id,
          webVisitorId: props.appointment.webVisitor?.id as string,
        });
      },
    [props.appointment.id, props.appointment.webVisitor?.id],
  );

  // 会計モーダルで確定後
  // - 未会計 -> 会計済(配送待ち) （telemedicine: true）
  // - 指導待ち -> 未会計 -> 完了 （telemedicine: false）
  const openChargeModal = useCallback(() => {
    setChargeModalState({
      isOpen: true,
      appointmentId: props.appointment.id,
    });
  }, [setChargeModalState, props.appointment.id]);

  // キャンセル
  const handleClickCancel = useRecoilCallback(
    ({ set }) =>
      () => {
        set(cancelPane, { isOpen: true });
      },
    [],
  );

  useEffect(() => {
    resetCancelPane();
  }, [props.appointment.id, resetCancelPane]);

  return (
    <>
      {isSentCongestionNotification ? (
        <Flex flexDirection="column" justifyContent="center">
          <Flex alignItems="center" justifyContent="center">
            <Button use="primary" wide="fill" size="l" onClick={handleSendNotification}>
              準備完了通知
            </Button>
            <FinishButton
              isSentCongestionNotification={isSentCongestionNotification}
              use="secondary"
              wide="fill"
              size="l"
              ml={theme.space.m}
              onClick={openChargeModal}
            >
              会計
            </FinishButton>
          </Flex>
          <Button use="white" onClick={handleClickCancel}>
            キャンセル
          </Button>
        </Flex>
      ) : (
        <Flex flexDirection="column" justifyContent="center">
          <Button
            use="primary"
            wide="fill"
            size="l"
            mb={theme.space.m}
            onClick={handleSendNotification}
          >
            準備完了通知
          </Button>
          <Flex alignItems="center" justifyContent="center">
            <CongestionButton use="default" wide="fill" size="l" onClick={handleCongestionClick}>
              混雑時通知
            </CongestionButton>
            <FinishButton
              isSentCongestionNotification={isSentCongestionNotification}
              use="secondary"
              wide="fill"
              size="l"
              ml={theme.space.m}
              onClick={openChargeModal}
            >
              会計
            </FinishButton>
          </Flex>
          <Button use="white" onClick={handleClickCancel}>
            キャンセル
          </Button>
        </Flex>
      )}
      <CancelPane align="center" canceling={canceling} onCancel={handleCancel} />
    </>
  );
};
