import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import {
  Alert,
  Box,
  Button,
  Drawer,
  EntryList,
  Flex,
  Grid,
  Header,
  Icon,
  List,
  Pagination,
  Radio,
  ScrollBox,
} from '~/components/blocks';
import { Sex } from '~/graphql';
import { newReceptionDrawerState } from '~/state/reception/atoms';
import { NewReceptionPanelMap } from '~/state/reception/types';
import { Label } from '~/utils/label';

import { DirectVisitorCandidate } from '../DirectVisitorCandidate';
import { DEFAULT_PER_PAGE } from '../InputDirectVisitorPanel/use-submit-direct_visitor';
import { useFetchDirectVisitors } from '../use-fetch-direct_visitors';
import { useCreateOrVisitDirectVisitor } from './use-create-or-visit-direct_visitor';

const SelectionList = styled(List)(({ theme }) =>
  css({ borderTop: 'none', marginTop: theme.space.m }),
);

const NewRadio = styled(Radio)(({ theme }) =>
  css({
    '& > p': {
      marginLeft: `calc(${theme.space.m} + ${theme.space.s})`,
    },
  }),
);

export const ConfirmReceptionDirectVisitorPanel = () => {
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const { inputValue, page, totalPage, existingPhoneNumber } =
    useRecoilValue(newReceptionDrawerState);
  const [selectedValue, setSelectedValue] = useState<string>('new');

  const { loading, directVisitors } = useFetchDirectVisitors();
  const { creating, error, boxRef, createOrVisit } = useCreateOrVisitDirectVisitor();

  const isSelectable = !loading && !creating;

  const handleChangePage = useRecoilCallback(
    ({ set }) =>
      (_page: number) =>
        set(newReceptionDrawerState, (_state) => ({
          ..._state,
          page: _page,
          perPage: DEFAULT_PER_PAGE,
        })),
    [],
  );
  const handleClickBack = useRecoilCallback(
    ({ set }) =>
      () =>
        set(newReceptionDrawerState, (_state) => ({
          ..._state,
          currentPanel: NewReceptionPanelMap.input,
        })),
    [],
  );
  const handleClickCandidate = useCallback((visitorId: string) => setSelectedValue(visitorId), []);
  const handleClickNew = useCallback(() => setSelectedValue('new'), []);
  const handleSubmit = useCallback(async () => {
    await createOrVisit(selectedValue);
  }, [createOrVisit, selectedValue]);

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="auto">
      <Header attached="drawer">新患受付</Header>
      <Box overflow="auto" ref={boxRef} paddingX={theme.space.l}>
        {error && <Alert marginBottom={theme.space.l}>{error}</Alert>}
        {!existingPhoneNumber && (
          <Alert status="info" marginBottom={theme.space.l}>
            入力内容を確認のうえ、「受付」ボタンをクリックしてください。
          </Alert>
        )}
        <EntryList size="s">
          <EntryList.Head>氏名</EntryList.Head>
          <EntryList.Body>
            {inputValue
              ? `${inputValue.familyName} ${inputValue.givenName}（${inputValue.phoneticFamilyName} ${inputValue.phoneticGivenName}）`
              : 'ー'}
          </EntryList.Body>
        </EntryList>
        <EntryList size="s">
          <EntryList.Head>生年月日</EntryList.Head>
          <EntryList.Body>
            {inputValue?.birthDate ? Label.warekiBirthDate(inputValue.birthDate) : 'ー'}
          </EntryList.Body>
        </EntryList>
        <EntryList size="s">
          <EntryList.Head>性別</EntryList.Head>
          <EntryList.Body>
            {inputValue?.sex ? Label.sex(inputValue.sex as Sex) : 'ー'}
          </EntryList.Body>
        </EntryList>
        <EntryList size="s">
          <EntryList.Head>電話番号（携帯）</EntryList.Head>
          <EntryList.Body>{inputValue?.mobilePhone ?? 'ー'}</EntryList.Body>
        </EntryList>
        {existingPhoneNumber && (
          <>
            <Alert status="warning" marginTop={theme.space.l}>
              同じ電話番号で登録された患者がいます。受付する患者、または「新規患者として受付」を選択して受付してください。
            </Alert>
            <Box overflow="auto">
              <ScrollBox ref={scrollRef} loaderLogoSize="half" loading={loading}>
                <SelectionList selectable={isSelectable}>
                  {directVisitors.map((directVisitor, idx) => (
                    <List.Item key={idx}>
                      <DirectVisitorCandidate
                        disabled={!isSelectable}
                        candidate={directVisitor}
                        radioName="directVisitor"
                        radioValue={selectedValue}
                        onClick={handleClickCandidate}
                      />
                    </List.Item>
                  ))}
                  <List.Item onClick={handleClickNew}>
                    <Flex alignItems="center" paddingX={theme.space.m}>
                      <NewRadio
                        name="directVisitor"
                        label="新規患者として受付"
                        value="new"
                        checked={selectedValue === 'new'}
                        onChange={handleClickNew}
                      />
                    </Flex>
                  </List.Item>
                </SelectionList>
              </ScrollBox>
              {totalPage > 1 && (
                <Flex justifyContent="center" marginTop={theme.space.m}>
                  <Pagination
                    currentPage={page}
                    totalPage={totalPage}
                    onChange={handleChangePage}
                  />
                </Flex>
              )}
            </Box>
          </>
        )}
        <Button
          size="s"
          use="white"
          marginTop={theme.space.l}
          disabled={loading || creating}
          onClick={handleClickBack}
        >
          <Icon icon="back-line" size="s" />
          戻る
        </Button>
      </Box>
      <Drawer.Footer>
        <Button use="base" wide="fill" disabled={loading} loading={creating} onClick={handleSubmit}>
          受付
        </Button>
      </Drawer.Footer>
    </Grid>
  );
};
