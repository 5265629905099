import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Box, Collapse, Icon } from '~/components/blocks';
import { useOrganizationElectronicPrescription } from '~/hooks/use-organization-electronic-prescription';

export const PrescriptionCollapse = () => {
  const theme = useTheme();
  const { electronicPrescription } = useOrganizationElectronicPrescription();

  return (
    <Collapse
      label={
        <>
          <Icon size="xxl" icon="drug" />
          {electronicPrescription ? '処方箋または処方内容（控え）' : '処方箋'}
        </>
      }
    >
      <Box padding={theme.space.m}>
        <Alert status="info">
          {electronicPrescription
            ? '患者の持参した処方箋または処方内容（控え）をご確認ください'
            : '患者の持参した処方箋をご確認ください'}
        </Alert>
      </Box>
    </Collapse>
  );
};
