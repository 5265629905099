import { useRecoilCallback, useRecoilValue } from 'recoil';

import {
  GetWebVisitDetailDocument,
  useSendCongestionNotificationForWebVisitorMutation,
} from '~/graphql';
import { webVisitorNotificationCongestionModalState } from '~/state/reception/atoms';

export const useSendCongestionNotification = () => {
  const { isOpen, appointmentId, webVisitorId } = useRecoilValue(
    webVisitorNotificationCongestionModalState,
  );

  const [sendNotification, { loading, error }] = useSendCongestionNotificationForWebVisitorMutation(
    {
      refetchQueries: [{ query: GetWebVisitDetailDocument, variables: { id: appointmentId } }],
    },
  );

  const handleSend = useRecoilCallback(
    ({ reset }) =>
      async (content: string) => {
        if (!appointmentId || !webVisitorId || !isOpen) return;

        try {
          await sendNotification({
            variables: {
              input: {
                webVisitorId,
                content,
                appointmentId,
              },
            },
          });

          reset(webVisitorNotificationCongestionModalState);
        } catch (_error) {
          // 何もしない
        }
      },
    [appointmentId, isOpen, sendNotification, webVisitorId],
  );

  return { loading, error, handleSend };
};
