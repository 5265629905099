import React from 'react';

import { Drawer } from '~/components/blocks';
import { BookedButtons } from '~/components/pages/Reception/CheckinEntryProfileDrawer/Footer/BookedButtons';
import { CheckinEntryDetailFragment, CheckinEntryStatus, PlanFeatureCode } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';

type Props = {
  checkinEntry?: CheckinEntryDetailFragment;
};

export const Footer = React.memo((props: Props) => {
  const { checkinEntry } = props;
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);
  const status = checkinEntry?.status;
  const isFinished = status === CheckinEntryStatus.Finished;

  if (!checkinEntry || (isFinished && !hasEnabledFollowup)) {
    return null;
  }

  if (isFinished) return null;

  return (
    <Drawer.Footer>{!isFinished && <BookedButtons checkinEntry={checkinEntry} />}</Drawer.Footer>
  );
});

Footer.displayName = 'Footer';
