import { Formik, FormikProps } from 'formik';
import React, { forwardRef, useCallback, useRef } from 'react';

import {
  EntryList,
  InputBirthdate,
  InputBirthdateAttr,
  Radio,
  RadioGroup,
  Text,
} from '~/components/blocks';
import { Fields } from '~/components/pages/Reception/GuestPatientForm/types';
import { validationSchema } from '~/components/pages/Reception/GuestPatientForm/validation';

type Props = {
  initialValues: Fields;
  onSubmit: (values: Fields) => void;
};

export const DirectVisitorForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const inputBirthdateRef = useRef<InputBirthdateAttr>(null);

  const handleChangeBirthdate = useCallback((date: string, formik: FormikProps<Fields>) => {
    formik.setFieldValue('birthDate', date);
  }, []);

  const handleReset = useCallback(() => {
    if (inputBirthdateRef.current) {
      inputBirthdateRef.current.reset();
    }
  }, []);

  return (
    <Formik
      enableReinitialize
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      {(formik) => (
        <>
          <EntryList>
            <EntryList.Head>氏名</EntryList.Head>
            <EntryList.Body>
              <Text fontWeight="bold">
                {`${formik.values.familyName} ${formik.values.givenName}`}
              </Text>
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>氏名（カナ）</EntryList.Head>
            <EntryList.Body>
              <Text fontWeight="bold">
                {`${formik.values.phoneticFamilyName} ${formik.values.phoneticGivenName}`}
              </Text>
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>生年月日</EntryList.Head>
            <EntryList.Body>
              <InputBirthdate
                error={formik.errors.birthDate}
                value={formik.values.birthDate}
                onChange={(date) => handleChangeBirthdate(date, formik)}
                ref={inputBirthdateRef}
              />
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>性別</EntryList.Head>
            <EntryList.Body>
              <RadioGroup>
                <Radio
                  name="sex"
                  label="男性"
                  value="male"
                  checked={formik.values.sex === 'male'}
                  onChange={formik.handleChange}
                />
                <Radio
                  name="sex"
                  label="女性"
                  value="female"
                  checked={formik.values.sex === 'female'}
                  onChange={formik.handleChange}
                />
              </RadioGroup>
            </EntryList.Body>
          </EntryList>
          <EntryList>
            <EntryList.Head>電話番号（携帯）</EntryList.Head>
            <EntryList.Body>
              <Text fontWeight="bold">{formik.values.mobilePhone}</Text>
            </EntryList.Body>
          </EntryList>
        </>
      )}
    </Formik>
  );
});

DirectVisitorForm.displayName = 'DirectVisitorForm';
