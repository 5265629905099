import React, { useCallback } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { Button, Flex } from '~/components/blocks';
import { CancelWaitingForChargePane } from '~/components/partials/CancelWaitingForChargePane';
import { WebVisitorAppointmentDetailFragment } from '~/graphql';
import { cancelPane } from '~/state/partials/cancel_pane/atoms';
import { webVisitorChargeModalState } from '~/state/reception/atoms';

import { useCancelAppointment } from './use-cancel-appointment';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
};

export const WaitingForChargeButtons = (props: Props) => {
  const setChargeModalState = useSetRecoilState(webVisitorChargeModalState);
  const { canceling, handleCancel } = useCancelAppointment(props.appointment.id);

  // 会計モーダルで確定後
  // - 未会計 -> 会計済(配送待ち) （telemedicine: true）
  // - 指導待ち -> 未会計 -> 完了 （telemedicine: false）
  const openChargeModal = useCallback(() => {
    setChargeModalState({
      isOpen: true,
      appointmentId: props.appointment.id,
    });
  }, [setChargeModalState, props.appointment.id]);

  // キャンセル
  const handleClickCancel = useRecoilCallback(
    ({ set }) =>
      () => {
        set(cancelPane, { isOpen: true });
      },
    [],
  );

  return (
    <>
      <Flex flexDirection="column" justifyContent="center">
        <Button use="secondary" wide="fill" size="l" onClick={openChargeModal}>
          会計
        </Button>
        <Button use="white" onClick={handleClickCancel}>
          キャンセル
        </Button>
      </Flex>
      <CancelWaitingForChargePane align="center" canceling={canceling} onCancel={handleCancel} />
    </>
  );
};
