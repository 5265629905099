import { keyframes, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { rgba } from 'polished';
import React, { useMemo } from 'react';
import { useRecoilCallback } from 'recoil';

import { Flex, Name, Table, Tag, Text } from '~/components/blocks';
import { ReceptionTypeIcon } from '~/components/partials';
import { ClinicsAppTag } from '~/components/partials/ClinicsAppTag';
import { DraftAppointmentStatusLabel } from '~/components/partials/DraftAppointmentStatusLabel';
import { ReceptionDraftAppointmentFragment } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  guestPatientProfileDrawerState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

type Props = {
  draftAppointment: ReceptionDraftAppointmentFragment;
  updated: boolean;
  activeMedixs: boolean;
};

const animation = keyframes`
    from {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    to {
        opacity: 1;
    }
`;

const TableRow = styled(Table.Tr, { shouldForwardProp })<{ updated: boolean }>(
  ({ theme, updated }) =>
    css({
      animation: updated ? `${animation} 1s ease 1` : 'none',
      '&:hover': {
        backgroundColor: rgba(theme.colors.background.default, 0.5),
        boxShadow: `inset 3px 0 0 ${theme.colors.background.primary}`,
      },
    }),
);

export const DraftAppointment = React.memo((props: Props) => {
  const theme = useTheme();
  const { draftAppointment } = props;
  const patient = draftAppointment.draftAppointmentPatient;
  const { enable: enabledUber } = useUberOrganizationSetting();

  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        set(draftAppointmentPatientProfileDrawerState, {
          isOpen: true,
          patientId: patient.id,
          draftAppointmentId: draftAppointment.id,
          error: null,
        });
        reset(checkinEntryProfileDrawerState);
        reset(profileDrawerState);
        reset(webVisitorProfileDrawerState);
        reset(guestPatientProfileDrawerState);
      },
    [draftAppointment.id, patient.id],
  );

  const memos = useMemo(
    () => draftAppointment.description.split(',').filter(Boolean),
    [draftAppointment.description],
  );

  return (
    <TableRow updated={props.updated} onClick={handleClick}>
      <Table.Td nowrap>
        <Flex alignItems="center">
          <Text size="m" fontWeight="bold" paddingLeft={theme.space.m}>
            未確定
          </Text>
        </Flex>
      </Table.Td>
      <Table.Td nowrap>
        <DraftAppointmentStatusLabel status={props.draftAppointment.draftAppointmentStatus} />
      </Table.Td>
      {props.activeMedixs && (
        <Table.Td nowrap>
          <Text color="navy" size="s">
            未連携
          </Text>
        </Table.Td>
      )}
      <Table.Td>
        <Name
          familyName={patient.familyName}
          givenName={patient.givenName}
          phoneticFamilyName={patient.phoneticFamilyName}
          phoneticGivenName={patient.phoneticGivenName}
        />
      </Table.Td>
      <Table.Td>
        <Text size="m" whiteSpace="nowrap">
          {Label.age(patient.birthDate)}
        </Text>
        <Text size="s" whiteSpace="nowrap">
          （{Label.sex(patient.sex)}）
        </Text>
      </Table.Td>
      <Table.Td>
        <Flex alignItems="center">
          <ReceptionTypeIcon telemedicine={draftAppointment.telemedicine} />
          <Text size="m" whiteSpace="nowrap" fontWeight="bold">
            {draftAppointment.telemedicine ? 'オンライン' : '対面'}
          </Text>
        </Flex>
      </Table.Td>
      {enabledUber && <Table.Td>-</Table.Td>}
      <Table.Td>-</Table.Td>
      <Table.Td>
        <ClinicsAppTag />
        {memos.map((memo, idx) => (
          <Tag key={idx} whiteSpace="nowrap">
            {memo}
          </Tag>
        ))}
      </Table.Td>
    </TableRow>
  );
});

DraftAppointment.displayName = 'DraftAppointment';
