import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Collapse, DateLabel, Flex, Icon } from '~/components/blocks';
import { AppointmentStatusLabel } from '~/components/partials';
import { HistoryVisitFragment } from '~/graphql';
import { useOrganizationElectronicPrescription } from '~/hooks/use-organization-electronic-prescription';
import { Label } from '~/utils/label';

type Props = {
  visit: HistoryVisitFragment;
};

export const History = (props: Props) => {
  const { visit } = props;
  const theme = useTheme();
  const { electronicPrescription } = useOrganizationElectronicPrescription();

  const day = Label.YYYYMMDDja(visit.createdAt);

  return (
    <Collapse label={day}>
      <Box padding={`0 ${theme.space.m} ${theme.space.l}`}>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" paddingY={theme.space.l}>
            <Icon icon="human" size="xxl" />
            <DateLabel orientation="vertical">
              <DateLabel.Day>{day}</DateLabel.Day>
              <DateLabel.Time>{Label.HHMM(visit.createdAt)}</DateLabel.Time>
            </DateLabel>
          </Flex>
          <AppointmentStatusLabel status="finished" />
        </Flex>
        <Collapse
          disabled
          disabledMessage={
            electronicPrescription
              ? '患者の持参した処方箋または処方内容（控え）をご確認ください'
              : '患者の持参した処方箋をご確認ください'
          }
          label={
            <>
              <Icon size="xxl" icon="drug" />
              {electronicPrescription ? '処方箋または処方内容（控え）' : '処方箋'}
            </>
          }
        />
      </Box>
    </Collapse>
  );
};
