import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { compose, space, SpaceProps } from 'styled-system';

import { Alert, Button, Divider, Icon, Text } from '~/components/blocks';
import {
  newReceptionDrawerState,
  prescriptionQrCodeReaderDialogState,
} from '~/state/reception/atoms';

type Props = {
  children: React.ReactNode;
} & SpaceProps;

const Root = styled('div', {
  shouldForwardProp,
})<SpaceProps>(
  ({ theme }) =>
    css({
      backgroundColor: theme.colors.background.bg,
      border: theme.borders.default,
      borderRadius: theme.radii.default,
      padding: `calc(${theme.space.s} + ${theme.space.m}) + ${theme.space.l}`,
      input: {
        backgroundColor: theme.colors.background.default,
        border: theme.borders.default,
      },
    }),
  compose(space),
);

export const InputForm: React.FC<Props> = (props) => {
  const { children, ...spaceProps } = props;

  const theme = useTheme();

  const { qrCodePatient } = useRecoilValue(newReceptionDrawerState);

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(prescriptionQrCodeReaderDialogState, { isOpen: true });
      },
    [],
  );

  return (
    <Root {...spaceProps}>
      <Button wide="fill" size="s" onClick={handleClick}>
        <Icon icon="qrcode" size="xl" />
        処方箋QRコード読み取り
      </Button>
      {qrCodePatient && (
        <Alert status="info" marginTop={theme.space.s}>
          <Text size="xs">
            処方箋QRコードから患者情報の読み取りが完了しました。
            <br />
            受付に必要な情報を追加・編集し、「受付」ボタンを押してください
          </Text>
        </Alert>
      )}
      <Divider margin={`${theme.space.m} 0`} />
      {children}
    </Root>
  );
};

InputForm.displayName = 'InputForm';
