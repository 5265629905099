import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Drawer, Grid, Loader } from '~/components/blocks';
import { DeleteMedicationFollowupMessageModal } from '~/components/partials';
import { VisitorProfile } from '~/components/partials/VisitorProfile';
import { useGetVisitDetailLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { useSwapDrawer } from '~/hooks/use-swap-drawer';
import { guestPatientProfileDrawerState, guestPatientTabState } from '~/state/reception/atoms';

import { GuestPatientKarte } from './GuestPatientKarte';

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const GuestPatientProfileDrawer = () => {
  const {
    state: { isOpen, visitId },
    handleTransitionEnd,
  } = useSwapDrawer(guestPatientProfileDrawerState, 'visitId');

  const [getVisit, { data, loading }] = useGetVisitDetailLazyQuery();
  const visit = getNode(data, 'Visit');
  const guestPatient = visit?.directVisitor;

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(guestPatientProfileDrawerState);
        reset(guestPatientTabState);
      },
    [],
  );

  useEffect(() => {
    if (isOpen && visitId) {
      getVisit({
        variables: {
          id: visitId,
        },
      });
    }
  }, [getVisit, isOpen, visitId]);

  return (
    <>
      <Drawer open={isOpen} onClose={handleClose} onTransitionEnd={handleTransitionEnd}>
        <Box position="relative" overflow="hidden" height="100%">
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <VisitorProfile loading={loading} visitor={guestPatient} />
            </ProfileBox>
            {guestPatient ? (
              <>
                <Box overflow="auto">
                  <GuestPatientKarte visit={visit} />
                </Box>
              </>
            ) : (
              <Loader inside open />
            )}
          </Grid>
        </Box>
      </Drawer>
      <DeleteMedicationFollowupMessageModal />
    </>
  );
};
