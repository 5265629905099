import React from 'react';

import { OrganizationAppShell } from '~/components/layouts';
import { Reception } from '~/components/pages/Reception';
import { FirebaseProvider } from '~/components/partials';

const ReceptionPage = () => {
  return (
    <FirebaseProvider>
      <OrganizationAppShell>
        <Reception />
      </OrganizationAppShell>
    </FirebaseProvider>
  );
};

export default ReceptionPage;
