import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { Sex, useEditDirectVisitorMutation } from '~/graphql';
import { useScrollToTop } from '~/hooks/use-scroll-to-top';
import { guestPatientTabState } from '~/state/reception/atoms';

import { Fields } from '../../../GuestPatientForm/types';

export const useEditGuestPatient = () => {
  const [containerRef, scrollToTop] = useScrollToTop<HTMLDivElement>();

  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const setPatientTabState = useSetRecoilState(guestPatientTabState);

  const [editGuestPatient] = useEditDirectVisitorMutation();

  const edit = useCallback(
    async (values: Fields, guestPatientId: string) => {
      setIsEditing(true);
      setError(null);

      const sex =
        values.sex === 'male' ? Sex.Male : values.sex === 'female' ? Sex.Female : Sex.Unknown;

      try {
        await editGuestPatient({
          variables: {
            input: {
              ...values,
              directVisitorId: guestPatientId,
              sex,
            },
          },
        });
        setPatientTabState((_state) => ({ ..._state, editableProfile: false }));
      } catch (error) {
        setError(error?.message || 'エラーが発生しました');
        scrollToTop();
      }
      setIsEditing(false);
    },
    [editGuestPatient, scrollToTop, setPatientTabState],
  );

  return {
    isEditing,
    error,
    containerRef,
    edit,
  };
};
