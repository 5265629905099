import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';

import { Box, Flex, Text } from '~/components/blocks';

type Props = PropsWithChildren<{ index: number }>;

const Root = styled(Flex)(({ theme }) =>
  css({
    '& .vertical': {
      textOrientation: 'upright',
      writingMode: 'vertical-rl',
    },
    '& .text_combine': {
      height: '100%',
      textAlign: 'center',
      letterSpacing: '0.4em',
      marginLeft: theme.space.m,
      marginRight: theme.space.m,
      color: theme.colors.text.entrytitle,
    },
    marginBottom: theme.space.l,
    border: theme.borders.default,
    borderRadius: theme.radii.default,
  }),
);

const Content = styled(Flex)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    width: '100%',
    paddingLeft: theme.space.m,
    paddingTop: theme.space.m,
  }),
);

export const PiecesContent = (props: Props) => {
  const { children, index } = props;
  const theme = useTheme();

  return (
    <Root>
      <Box className="vertical" paddingY={theme.space.m}>
        <Text className="text_combine">{index + 1}つめ</Text>
      </Box>
      <Content>{children}</Content>
    </Root>
  );
};
