const KEY = 'org';

export const setOrganizationName = (organizationName: string) => {
  window.localStorage.setItem(KEY, organizationName);
};

export const getOrganizationName = () => {
  return window.localStorage.getItem(KEY);
};

export const clearOrganizationName = () => {
  window.localStorage.removeItem(KEY);
};
