import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { newReceptionDrawerState } from '~/state/reception/atoms';

import { Fields } from '../../GuestPatientForm/types';

export const useDefaultValues = () => {
  const { selectedCandidate, qrCodePatient } = useRecoilValue(newReceptionDrawerState);

  const defaultValues: Fields = useMemo(() => {
    if (!selectedCandidate) {
      return {
        familyName: '',
        givenName: '',
        phoneticFamilyName: '',
        phoneticGivenName: '',
        birthDate: '',
        sex: 'male',
        mobilePhone: '',
      };
    }

    let mobilePhone = '';
    if (selectedCandidate.__typename === 'DirectVisitor') {
      mobilePhone = selectedCandidate.mobilePhone;
    } else if (selectedCandidate.__typename === 'CheckinEntry') {
      mobilePhone = selectedCandidate.phoneNumber;
    }

    if (qrCodePatient) {
      return {
        ...qrCodePatient,
        mobilePhone,
      };
    } else {
      return {
        familyName: '',
        givenName: '',
        phoneticFamilyName: '',
        phoneticGivenName: '',
        birthDate: '',
        sex: 'male',
        mobilePhone,
      };
    }
  }, [qrCodePatient, selectedCandidate]);

  return defaultValues;
};
