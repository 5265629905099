import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import React from 'react';

import { Box, Loader, Text } from '~/components/blocks';

import { DeviceError } from './DeviceError';
import { FailureResult } from './FailureResult';
import { useReadQrCode } from './use-read-qr_code';

const CodeReader = styled('div')(({ theme }) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.space.l,
    overflow: 'hidden',
    position: 'relative',
    height: '560px',
  }),
);

const Message = styled(Box)(({ theme }) =>
  css({
    textAlign: 'center',
    fontSize: theme.fontSizes.m,
    fontWeight: theme.fontWeights.bold,
    borderRadius: theme.radii.default,
    padding: theme.space.m,
    color: theme.colors.text.white,
  }),
);

const QrCodeFlame = styled('div')(() =>
  css({
    position: 'relative',
    width: '560px',
    height: '560px',
    top: '0px',
    left: '0px',
    zIndex: 2,
    border: `70px solid ${rgba(0, 0, 0, 0.3)} `,
    boxShadow: `${rgba(255, 0, 0, 0.5)} 0px 0px 0px 5px inset`,
    boxSizing: 'border-box',
  }),
);

const VideoFlame = styled('div')(({ theme }) =>
  css({
    position: 'absolute',
    borderLeft: `52px solid ${theme.colors.background.black}`,
    borderRight: `52px solid ${theme.colors.background.black}`,
    top: '-2px',
    width: '664px',
    height: '102%',
    zIndex: 1,
  }),
);

const Video = styled('video')(() =>
  css({
    zIndex: 0,
    objectFit: 'cover',
    position: 'absolute',
    aspectRatio: 'auto 660 / 560',
    width: '660px',
    height: '560px',
  }),
);

export const QrCodeReader = () => {
  const theme = useTheme();
  const { videoRef, loading, hasDeviceError, error, resetError } = useReadQrCode();

  return (
    <Box>
      {hasDeviceError ? (
        <DeviceError />
      ) : (
        <>
          <Message>
            赤枠に収まるように処方箋のQRコードを一つ配置してください。
            <br />
            読み取りが完了するまで、数秒お待ちください
            <br />
            <Text color="white" size="s" marginTop={theme.space.l}>
              読み取れない場合、QRコードを遠ざけたり近づけるように、ゆっくり動かしてください。
            </Text>
          </Message>
          <CodeReader>
            <Loader inside open={loading} />
            <QrCodeFlame />
            <VideoFlame />
            <Video ref={videoRef} />
            {error && <FailureResult error={error} onClick={resetError} />}
          </CodeReader>
        </>
      )}
    </Box>
  );
};
