import { useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { ScrollBox } from '~/components/blocks';
import { useGetVisitHistoriesLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { guestPatientHistoryPageInfoState } from '~/state/reception/atoms';

export const useFetchHistories = (guestPatientId: string) => {
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);

  const [pageInfo, setPageInfo] = useRecoilState(guestPatientHistoryPageInfoState);
  const [getVisits, { loading, data }] = useGetVisitHistoriesLazyQuery({
    onCompleted: (result) => {
      const visitor = getNode(result, 'DirectVisitor');
      setPageInfo((_state) => ({ ..._state, totalPage: visitor?.visits.pagesCount || 1 }));
      scrollRef.current?.toTop();
    },
  });
  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const visits = useMemo(
    () => (currentData ? getNode(currentData, 'DirectVisitor')?.visits.nodes || [] : []),
    [currentData],
  );

  useEffect(() => {
    if (guestPatientId !== pageInfo.guestPatientId) {
      setPageInfo((_state) => ({
        ..._state,
        guestPatientId,
        page: 1,
        totalPage: 1,
      }));
    }
  }, [guestPatientId, pageInfo.guestPatientId, setPageInfo]);

  useEffect(() => {
    if (pageInfo.guestPatientId) {
      getVisits({
        variables: {
          id: guestPatientId,
          page: pageInfo.page,
          perPage: pageInfo.perPage,
        },
      });
    }
  }, [getVisits, guestPatientId, pageInfo.guestPatientId, pageInfo.page, pageInfo.perPage]);

  return {
    loading,
    visits,
    scrollRef,
  };
};
