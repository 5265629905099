import { useEffect } from 'react';

import { useGetDraftAppointmentOnRequestAppointmentModalLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';

type Props = {
  draftAppointmentId: string;
};

export const useFetchOrganizationName = ({ draftAppointmentId }: Props) => {
  const [getDraftAppointment, { data }] =
    useGetDraftAppointmentOnRequestAppointmentModalLazyQuery();

  const draftAppointment = getNode(data, 'DraftAppointment');
  const organizationName = draftAppointment ? draftAppointment.organization.name : '';

  useEffect(() => {
    if (draftAppointmentId) {
      getDraftAppointment({ variables: { draftAppointmentId } });
    }
  }, [draftAppointmentId, getDraftAppointment]);

  return { organizationName };
};
