import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useRef, useState } from 'react';

import { Button, Checkbox, CheckboxGroup, Icon, Overlay, Popover } from '~/components/blocks';
import { OrganizationAppointmentStatus } from '~/graphql';

type Props = {
  value: OrganizationAppointmentStatus[];
  onChange: (statuses: OrganizationAppointmentStatus[]) => void;
};

type StatusOrder = {
  label: string;
  order: number;
};

const statuses: Record<OrganizationAppointmentStatus, StatusOrder> = {
  status1: { label: '未受付', order: 1 },
  status2: { label: '指導待ち', order: 2 },
  status7: { label: '集荷待ち', order: 3 },
  status3: { label: '未会計', order: 4 },
  status4: { label: '配達待ち', order: 5 },
  status5: { label: '完了', order: 6 },
  status6: { label: 'キャンセル', order: 7 },
};

const FilterList = styled('div')(({ theme }) =>
  css({
    background: theme.colors.background.default,
    borderRadius: theme.radii.default,
    boxShadow: theme.shadows.default,
    padding: theme.space.l,
  }),
);

export const StatusFilter: React.FC<Props> = (props) => {
  const { onChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const anckerRef = useRef<HTMLButtonElement>(null);
  const label =
    props.value
      .slice()
      .sort((x, y) => {
        return statuses[x].order - statuses[y].order;
      })
      .map((v) => statuses[v].label)
      .join(' | ') || 'ステータス選択';
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const status = e.currentTarget.value;
      const checked = e.currentTarget.checked;
      let newStatuses: OrganizationAppointmentStatus[] = [];

      if (checked) {
        newStatuses = [...props.value, status] as OrganizationAppointmentStatus[];
      } else {
        newStatuses = props.value.filter((v) => v != status);
      }
      onChange(newStatuses);
    },
    [onChange, props.value],
  );
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Button
        type="button"
        use="white"
        ref={anckerRef}
        onClick={() => setIsOpen(isOpen ? false : true)}
      >
        {label}
        <Icon size="m" icon="arrow-down" />
      </Button>
      {anckerRef.current && (
        <Overlay transparent open={isOpen} onClick={handleClose}>
          <Popover
            inside
            stopPropagation
            open={isOpen}
            placement="bottom"
            anckerEl={anckerRef.current}
          >
            <FilterList>
              <CheckboxGroup orientation="vertical">
                <Checkbox
                  label={statuses.status1.label}
                  value="status1"
                  checked={props.value.includes('status1')}
                  onChange={handleChange}
                />
                <Checkbox
                  label={statuses.status2.label}
                  value="status2"
                  checked={props.value.includes('status2')}
                  onChange={handleChange}
                />
                <Checkbox
                  label={statuses.status7.label}
                  value="status7"
                  checked={props.value.includes('status7')}
                  onChange={handleChange}
                />
                <Checkbox
                  label={statuses.status3.label}
                  value="status3"
                  checked={props.value.includes('status3')}
                  onChange={handleChange}
                />
                <Checkbox
                  label={statuses.status4.label}
                  value="status4"
                  checked={props.value.includes('status4')}
                  onChange={handleChange}
                />
                <Checkbox
                  label={statuses.status5.label}
                  value="status5"
                  checked={props.value.includes('status5')}
                  onChange={handleChange}
                />
                <Checkbox
                  label={statuses.status6.label}
                  value="status6"
                  checked={props.value.includes('status6')}
                  onChange={handleChange}
                />
              </CheckboxGroup>
            </FilterList>
          </Popover>
        </Overlay>
      )}
    </>
  );
};
