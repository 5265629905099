import { CheckinEntryItemFragment } from '~/graphql';

import { Fields } from '../GuestPatientForm/types';

export const useDefaultValues = (checkinEntry: CheckinEntryItemFragment) => {
  const defaultValues: Fields = {
    familyName: checkinEntry.familyName,
    givenName: checkinEntry.givenName,
    phoneticFamilyName: checkinEntry.phoneticFamilyName,
    phoneticGivenName: checkinEntry.phoneticGivenName,
    birthDate: '',
    sex: 'male',
    mobilePhone: checkinEntry.phoneNumber,
  };

  return defaultValues;
};
