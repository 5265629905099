import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { AppointmentDeliveryMethod, useGetAppointmentsForDeliveryLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { b2CloudDownloadModalState } from '~/state/reception/atoms';

// ページングなしで表示したいので、1日の最大予約受け入れ数 + 500を取得する
// 無限スクロールに置き換えても良さそう
const DEFAULT_PER_PAGE = 1500;
const B2CLOUD_DELIVERY_METHODS = [
  AppointmentDeliveryMethod.B2cloudCashOnDelivery,
  AppointmentDeliveryMethod.B2cloudCollect,
  AppointmentDeliveryMethod.B2cloudCompact,
  AppointmentDeliveryMethod.B2cloudCompactCollect,
  AppointmentDeliveryMethod.B2cloudDm,
  AppointmentDeliveryMethod.B2cloudNekopos,
  AppointmentDeliveryMethod.B2cloudPrepayment,
  AppointmentDeliveryMethod.B2cloudTime,
];

export const useFetchAppointments = () => {
  const { isOpen } = useRecoilValue(b2CloudDownloadModalState);

  const [getAppointments, { data, loading }] = useGetAppointmentsForDeliveryLazyQuery({
    fetchPolicy: 'network-only',
  });
  const appointments = useMemo(
    () => (data ? getMe(data)?.organization.appointments.nodes || [] : []),
    [data],
  );

  useEffect(() => {
    if (isOpen) {
      getAppointments({
        variables: {
          page: 1,
          perPage: DEFAULT_PER_PAGE,
          statuses: ['status4'], // 配送済み
          deliveryMethods: B2CLOUD_DELIVERY_METHODS,
        },
      });
    }
  }, [getAppointments, isOpen]);

  return { loading, appointments };
};
