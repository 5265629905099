import { array, number, object, ObjectSchema } from 'yup';

import { FormErrorMessage } from '~/constants/messages';

import { Fields } from './type';

export const validationSchema: ObjectSchema<Fields> = object({
  quantity: number()
    .label('荷物')
    .required()
    .min(1, FormErrorMessage.min)
    .max(5, FormErrorMessage.max),
  pieces: array()
    .of(
      number()
        .integer(FormErrorMessage.integer)
        .min(1, FormErrorMessage.min)
        .required(FormErrorMessage.integer),
    )
    .label('梱包物')
    .required()
    .min(1, '梱包物は1つ以上入力してください'),
});
