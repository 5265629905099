import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Pagination } from '~/components/blocks';
import { bookmarkPatientListModalState } from '~/state/reception/atoms';

export const BookmarkPatientPagination = () => {
  const [pageInfo, setPageInfo] = useRecoilState(bookmarkPatientListModalState);
  const handleChangePage = useCallback(
    (page: number) => {
      setPageInfo((state) => ({ ...state, page }));
    },
    [setPageInfo],
  );

  return (
    <Pagination
      currentPage={pageInfo.page}
      totalPage={pageInfo.totalPage}
      onChange={handleChangePage}
    />
  );
};
