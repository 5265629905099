import { useEffect, useMemo } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { useGetInvitationCodesLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { invitationHistoryPageInfoState, invitationModalState } from '~/state/reception/atoms';

export const useInvitationHistory = () => {
  const [state, setState] = useRecoilState(invitationHistoryPageInfoState);
  const { isOpen } = useRecoilValue(invitationModalState);
  const [getInvitationCodes, { loading, data }] = useGetInvitationCodesLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      setState((state) => ({
        ...state,
        totalPage: getMe(_data)?.organization.invitationCodes.pagesCount || 1,
      }));
    },
  });
  const invitationCodes = useMemo(
    () => (data ? getMe(data)?.organization.invitationCodes.nodes || [] : []),
    [data],
  );
  const handleChangePage = useRecoilCallback(
    ({ set }) =>
      (page: number) =>
        set(invitationHistoryPageInfoState, (_state) => ({
          ..._state,
          page,
        })),
    [],
  );
  const handleSendFailedFilter = useRecoilCallback(
    ({ set }) =>
      (e: React.ChangeEvent<HTMLInputElement>) =>
        set(invitationHistoryPageInfoState, (_state) => ({
          ..._state,
          page: 1,
          isSendFailed: e.target.checked,
        })),
    [],
  );

  const handleNotAppliedFilter = useRecoilCallback(
    ({ set }) =>
      (e: React.ChangeEvent<HTMLInputElement>) =>
        set(invitationHistoryPageInfoState, (_state) => ({
          ..._state,
          page: 1,
          isNotApplied: e.target.checked,
        })),
    [],
  );

  useEffect(() => {
    if (isOpen) {
      getInvitationCodes({
        variables: {
          sendFailed: state.isSendFailed,
          notApplied: state.isNotApplied,
          page: state.page,
          perPage: state.perPage,
        },
      });
    }
  }, [
    getInvitationCodes,
    state.isSendFailed,
    state.isNotApplied,
    state.page,
    state.perPage,
    isOpen,
  ]);

  return {
    loading,
    state,
    invitationCodes,
    handleChangePage,
    handleSendFailedFilter,
    handleNotAppliedFilter,
  };
};
