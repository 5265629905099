import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Flex, Icon, Text, TextField } from '~/components/blocks';
import { numberOrHyphenRegexp } from '~/constants/regexp';
import { newReceptionDrawerState } from '~/state/reception/atoms';
import { NewReceptionPanelMap } from '~/state/reception/types';
import { toHalfNumberWithHyphen } from '~/utils/convert';
import { throttle } from '~/utils/throttle';

const DEFAULT_PER_PAGE = 10;

type Props = {
  disabled?: boolean;
};

const Root = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    border: theme.borders.default,
    borderRadius: theme.radii.default,
    padding: `calc(${theme.space.s} + ${theme.space.m}) + ${theme.space.l}`,
  }),
);

const SearchBox = styled(Flex, {
  shouldForwardProp,
})(({ theme }) =>
  css({
    alignItems: 'center',
    backgroundColor: theme.colors.background.default,
    border: theme.borders.default,
    borderRadius: theme.radii.default,
    fontWeight: theme.fontWeights.default,
    paddingLeft: theme.space.m,
    transitionDuration: theme.transitions.default,
    pointerEvents: 'none',
    width: '100%',
    '&:hover': {
      borderColor: theme.colors.border.primary,
    },
    svg: {
      marginRight: theme.space.m,
    },
    input: {
      backgroundColor: theme.colors.background.default,
      borderRadius: `0 8px 8px 0`,
      paddingLeft: 0,
      pointerEvents: 'auto',
      '&:hover': {
        borderColor: 'transparent',
      },
      '&:focus': {
        borderColor: 'transparent',
      },
    },
  }),
);

const throttleInput = throttle(500);

export const SearchDirectVisitors = React.memo((props: Props) => {
  const { disabled } = props;
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { searchWord: _searchWord } = useRecoilValue(newReceptionDrawerState);
  const [searchWord, setSearchWord] = useState(_searchWord);

  const handleChange = useRecoilCallback(
    ({ set }) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const word = e.target.value;

        setSearchWord(word);
        set(newReceptionDrawerState, (_state) => ({
          ..._state,
          page: 1,
          totalPage: 1,
          perPage: DEFAULT_PER_PAGE,
        }));

        throttleInput(() => {
          set(newReceptionDrawerState, (_state) => ({
            ..._state,
            searchWord: word,
            currentPanel: NewReceptionPanelMap.select,
          }));
        });
      },
    [],
  );
  const handleCompositionEnd = useRecoilCallback(
    ({ set }) =>
      (e: React.CompositionEvent<HTMLInputElement>) => {
        let word = e.currentTarget.value;

        if (numberOrHyphenRegexp.test(word)) {
          word = toHalfNumberWithHyphen(word);

          setSearchWord(word);
          throttleInput(() => {
            set(newReceptionDrawerState, (_state) => ({
              ..._state,
              searchWord: word,
              currentPanel: NewReceptionPanelMap.select,
            }));
          });
        }
      },
    [],
  );

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Root>
      <SearchBox>
        <Icon size="l" icon="search" />
        <TextField
          ref={inputRef}
          disabled={disabled}
          placeholder="患者名・電話番号で検索"
          value={searchWord}
          onChange={handleChange}
          onCompositionEnd={handleCompositionEnd}
        />
      </SearchBox>
      <Text size="xs" marginTop={theme.space.s}>
        ※ CLINICSアプリ患者は検索対象外です
        <br /> ※ 姓名の間にスペース（空白）を入れて検索してください
      </Text>
    </Root>
  );
});

SearchDirectVisitors.displayName = 'SearchDirectVisitors';
