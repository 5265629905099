import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Drawer } from '~/components/blocks';
import { InputCheckinEntryVisitorPanel } from '~/components/pages/Reception/NewReceptionDrawer/InputCheckinEntryVisitorPanel';
import { newReceptionDrawerState } from '~/state/reception/atoms';
import { NewReceptionPanelMap } from '~/state/reception/types';

import { ConfirmReceptionDirectVisitorPanel } from './ConfirmReceptionDirectVisitorPanel';
import { InputDirectVisitorFamilyPanel } from './InputDirectVisitorFamilyPanel';
import { InputDirectVisitorPanel } from './InputDirectVisitorPanel';
import { QrCodeReaderDialog } from './QrCodeReaderDialog';
import { SelectReceptionDirectVisitorPanel } from './SelectReceptionDirectVisitorPanel';

export const NewReceptionDrawer = () => {
  const { isOpen, currentPanel } = useRecoilValue(newReceptionDrawerState);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(newReceptionDrawerState),
    [],
  );

  return (
    <>
      <Drawer open={isOpen} onClose={handleClose}>
        {currentPanel === NewReceptionPanelMap.input && <InputDirectVisitorPanel />}
        {currentPanel === NewReceptionPanelMap.confirm && <ConfirmReceptionDirectVisitorPanel />}
        {currentPanel === NewReceptionPanelMap.select && <SelectReceptionDirectVisitorPanel />}
        {currentPanel === NewReceptionPanelMap.inputFamily && <InputDirectVisitorFamilyPanel />}
        {currentPanel === NewReceptionPanelMap.checkin && <InputCheckinEntryVisitorPanel />}
      </Drawer>
      <QrCodeReaderDialog />
    </>
  );
};
