import { useTheme } from '@emotion/react';
import React, { useCallback, useRef } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Box, Button, Drawer, Grid, Header, Icon, Text } from '~/components/blocks';
import { useScrollToTop } from '~/hooks/use-scroll-to-top';
import { newReceptionDrawerState } from '~/state/reception/atoms';
import { NewReceptionPanelMap } from '~/state/reception/types';

import { GuestPatientForm } from '../../GuestPatientForm';
import { Fields } from '../../GuestPatientForm/types';
import { InputForm } from '../InputForm';
import { useCreateDirectVisitor } from '../use-create-direct_visitor';
import { useDefaultValues } from './use-default_values';

export const InputDirectVisitorFamilyPanel = () => {
  const theme = useTheme();
  const formRef = useRef<RefAttributeType<typeof GuestPatientForm>>(null);
  const [containerRef, scrollToTop] = useScrollToTop<HTMLDivElement>();

  const { selectedCandidate } = useRecoilValue(newReceptionDrawerState);

  const defaultValues = useDefaultValues();
  const { creating, error, create } = useCreateDirectVisitor();

  const handleClick = useRecoilCallback(
    ({ set }) =>
      () =>
        set(newReceptionDrawerState, (_state) => ({
          ..._state,
          currentPanel: NewReceptionPanelMap.select,
        })),
    [],
  );
  const handleSend = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);
  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        await create(values);
        formRef.current?.resetForm();
      } catch {
        scrollToTop();
      }
    },
    [create, scrollToTop],
  );

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="auto">
      <Header attached="drawer">新患受付</Header>
      <Box ref={containerRef} overflow="auto" paddingX={theme.space.l}>
        {error && (
          <Alert status="error" marginBottom={theme.space.l} whiteSpace="pre-wrap">
            {error}
          </Alert>
        )}
        <InputForm>
          <GuestPatientForm
            ref={formRef}
            disabled={creating}
            initialValues={defaultValues}
            mobilePhone={
              <>
                <Text fontWeight="bold" block>
                  {defaultValues.mobilePhone}
                </Text>
                {selectedCandidate && (
                  <Text size="xs">{`※ ${selectedCandidate.familyName} ${selectedCandidate.givenName}（${selectedCandidate.phoneticFamilyName} ${selectedCandidate.phoneticGivenName}）さんご利用中の電話番号`}</Text>
                )}
              </>
            }
            onSubmit={handleSubmit}
          />
        </InputForm>
        <Button
          size="s"
          use="white"
          marginTop={theme.space.l}
          disabled={creating}
          onClick={handleClick}
        >
          <Icon icon="back-line" size="s" />
          戻る
        </Button>
      </Box>
      <Drawer.Footer>
        <Button use="base" wide="fill" loading={creating} onClick={handleSend}>
          受付
        </Button>
      </Drawer.Footer>
    </Grid>
  );
};
