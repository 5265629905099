import React from 'react';

import { EntryList } from '~/components/blocks';
import { DirectVisitorDetailFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  guestPatient: DirectVisitorDetailFragment;
};

export const GuestPatientProfile = React.memo((props: Props) => {
  const { guestPatient } = props;

  return (
    <>
      <EntryList>
        <EntryList.Head>氏名</EntryList.Head>
        <EntryList.Body>{`${guestPatient.familyName} ${guestPatient.givenName}`}</EntryList.Body>
      </EntryList>
      <EntryList>
        <EntryList.Head>氏名（カナ）</EntryList.Head>
        <EntryList.Body>{`${guestPatient.phoneticFamilyName} ${guestPatient.phoneticGivenName}`}</EntryList.Body>
      </EntryList>
      <EntryList>
        <EntryList.Head>生年月日</EntryList.Head>
        <EntryList.Body>{Label.warekiBirthDate(guestPatient.birthDate)}</EntryList.Body>
      </EntryList>
      <EntryList>
        <EntryList.Head>性別</EntryList.Head>
        <EntryList.Body>{Label.sex(guestPatient.sex)}</EntryList.Body>
      </EntryList>
      <EntryList>
        <EntryList.Head>電話番号（携帯）</EntryList.Head>
        <EntryList.Body>{guestPatient.mobilePhone}</EntryList.Body>
      </EntryList>
    </>
  );
});

GuestPatientProfile.displayName = 'GuestPatientProfile';
