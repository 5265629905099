import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Button, Flex, Icon, Text } from '~/components/blocks';
import { bookmarkPatientListModalState } from '~/state/reception/atoms';

export const BookmarkButton = () => {
  const theme = useTheme();
  const handleClick = useRecoilCallback(({ set }) => () => {
    set(bookmarkPatientListModalState, (_state) => ({ ..._state, isOpen: true }));
  });

  return (
    <Button size="s" ml={theme.space.m} onClick={handleClick}>
      <Flex>
        <Icon size="l" color="pink" icon="favorite-fill" marginRight={theme.space.s} />
        <Text size="xs">お気に入り薬局登録者</Text>
      </Flex>
    </Button>
  );
};
