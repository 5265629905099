import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { useGetDirectVisitorsLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { usePreviousValue } from '~/hooks/use-previous-value';
import { newReceptionDrawerState } from '~/state/reception/atoms';

export const useFetchDirectVisitors = () => {
  const [{ inputValue, searchWord, page, perPage }, setState] =
    useRecoilState(newReceptionDrawerState);

  const [getDirectVisitors, { called, loading, data }] = useGetDirectVisitorsLazyQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (_result) => {
      const totalPage = getMe(_result)?.organization.directVisitors.pagesCount ?? 1;
      setState((_state) => ({ ..._state, totalPage }));
    },
  });
  const prevData = usePreviousValue(data);
  const currentData = data || prevData;
  const directVisitors = currentData
    ? getMe(currentData)?.organization.directVisitors.nodes ?? []
    : [];

  useEffect(() => {
    if (inputValue?.mobilePhone || searchWord.length > 0)
      getDirectVisitors({
        variables: {
          page,
          perPage,
          searchWord,
          phoneNumber: inputValue?.mobilePhone ?? null,
        },
      });
  }, [getDirectVisitors, inputValue?.mobilePhone, page, perPage, searchWord]);

  return {
    loading: !called || loading,
    directVisitors,
  };
};
