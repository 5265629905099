import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useMemo } from 'react';

import { Alert, Button, EntryList, Icon, Modal, Text } from '~/components/blocks';

import { useRequestAppointment } from './use-request-appointment';

type Props = {
  onBack: () => void;
  organizationName: string;
  draftAppointmentId: string;
};

const Message = styled('header')(({ theme }) =>
  css({
    whiteSpace: 'pre-wrap',
    background: theme.colors.background.bg,
    padding: theme.space.l,
  }),
);

export const MessageSendPanel = (props: Props) => {
  const theme = useTheme();
  const { loading, error, handleRequest } = useRequestAppointment({
    draftAppointmentId: props.draftAppointmentId,
  });

  const content = useMemo(
    () =>
      `${props.organizationName}にて日時の選択が可能になりました。日時を選択して申し込みを完了してください`,
    [props.organizationName],
  );

  return (
    <>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため送信できませんでした</Alert>}
        <Text>メッセージ内容を確認のうえ、「送信」ボタンをクリックしてください。</Text>
        <EntryList mt={theme.space.m}>
          <EntryList.Head>送信メッセージ</EntryList.Head>
          <EntryList.Body>
            <Message>{content}</Message>
          </EntryList.Body>
        </EntryList>
      </Modal.Body>
      <Modal.Footer both>
        <Button use="white" onClick={props.onBack}>
          <Icon icon="back-line" size="m" />
          戻る
        </Button>
        <Button use="secondary" loading={loading} onClick={() => handleRequest()}>
          送信
        </Button>
      </Modal.Footer>
    </>
  );
};
