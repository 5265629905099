import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Chip, Icon, Table, Text } from '~/components/blocks';
import { InvitationCodeItemFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  invitationHistory: InvitationCodeItemFragment;
};

const SendFailed = styled('div')(({ theme }) =>
  css({
    color: theme.colors.text.error,
    fontSize: theme.fontSizes.s,
    fontWeight: theme.fontWeights.bold,
  }),
);

export const InvitationHistoryItem = React.memo((props: Props) => {
  const theme = useTheme();
  const { invitationHistory } = props;
  const isSendFailed =
    invitationHistory.accreteMessage?.resultCode !== '0000' ||
    invitationHistory.accreteMessage.accreteMessageResults.length
      ? invitationHistory.accreteMessage?.accreteMessageResults.every((r) => r.result !== 'S')
      : false;
  const practitioner = invitationHistory.accreteMessage?.practitioner;
  // NOTE: 開発環境では未登録番号への送信時はregisterTimeがnullになるため、メッセージ作成日時を送信日時とする
  const sendAt =
    invitationHistory.accreteMessage?.registerTime ?? invitationHistory.accreteMessage?.createdAt;
  const hasAppointment = !!invitationHistory.appointment;

  return (
    <Table.Tr>
      <Table.Td>
        <Text block size="m" whiteSpace="nowrap">
          {`${Label.YYYYMMDDja(sendAt as string)} ${Label.HHMM(sendAt as string)}`}
        </Text>
        {isSendFailed && (
          <SendFailed>
            <Icon size="l" icon="attention" mr={theme.space.s} />
            送信エラー
          </SendFailed>
        )}
      </Table.Td>
      <Table.Td>{invitationHistory.code}</Table.Td>
      <Table.Td>{invitationHistory.accreteMessage?.phoneNumber}</Table.Td>
      <Table.Td>{invitationHistory.memo}</Table.Td>
      <Table.Td>
        {practitioner?.familyName}&nbsp;{practitioner?.givenName}
      </Table.Td>
      <Table.Td>
        {!isSendFailed && hasAppointment && (
          <Chip size="fixed" color="green" textColor="white">
            あり
          </Chip>
        )}
        {!isSendFailed && !hasAppointment && (
          <Chip size="fixed" color="grey03" textColor="black">
            なし
          </Chip>
        )}
      </Table.Td>
    </Table.Tr>
  );
});

InvitationHistoryItem.displayName = 'InvitationHistoryItem';
