import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Checkbox, Flex, Name, Table, Text } from '~/components/blocks';
import {
  AppointmentStatusLabel,
  DeliveryTag,
  isDeliveryTag,
  ReceptionTypeIcon,
} from '~/components/partials';
import { AppointmentForDeliveryFragment } from '~/graphql';
import { b2CloudDownloadModalState } from '~/state/reception/atoms';
import { Label } from '~/utils/label';

type Props = {
  appointment: AppointmentForDeliveryFragment;
  checked: boolean;
  onClick: (id: string) => void;
};

export const Appointment = React.memo((props: Props) => {
  const { checked, appointment, onClick } = props;
  const { patient } = appointment;

  const { disabled } = useRecoilValue(b2CloudDownloadModalState);
  const outputMemoes = appointment.description.split(',').filter((tag) => isDeliveryTag(tag));

  const handleClick = useCallback(() => onClick(appointment.id), [appointment.id, onClick]);
  const handleClickCheckbox = useCallback((e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // FIXME: Table.TrのonClickが2回呼ばれてしまうため、イベントの伝播を防ぐ
    e.stopPropagation();
  }, []);

  return (
    <Table.Tr onClick={handleClick}>
      <Table.Td textAlign="center">
        <Flex alignItems="center">
          <Checkbox
            checked={checked}
            onChange={handleClick}
            disabled={disabled}
            onClick={handleClickCheckbox}
          />
        </Flex>
      </Table.Td>
      <Table.Td>
        <Text size="xs" fontWeight="bold" whiteSpace="nowrap">
          {Label.YYYYMMDDja(appointment.createdAt)}
        </Text>
      </Table.Td>
      <Table.Td>
        <Flex alignItems="center">
          <ReceptionTypeIcon telemedicine={appointment.telemedicine} />
          <Name
            familyName={patient?.familyName}
            givenName={patient?.givenName}
            phoneticFamilyName={patient?.phoneticFamilyName}
            phoneticGivenName={patient?.phoneticGivenName}
            size="s"
          />
        </Flex>
      </Table.Td>
      <Table.Td>
        {outputMemoes.length > 0 && <DeliveryTag text={outputMemoes[outputMemoes.length - 1]} />}
      </Table.Td>
      <Table.Td textAlign="right">
        <AppointmentStatusLabel status={appointment.status} />
      </Table.Td>
    </Table.Tr>
  );
});

Appointment.displayName = 'Appointment';
