import React, { useCallback, useState } from 'react';

import { Grid, Tabs } from '~/components/blocks';
import { GuestPatientTabMap, TabMap } from '~/constants/tab';
import { CheckinEntryDetailFragment, PlanFeatureCode } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';

import { FilePane } from './FilePane';
import { HistoryPane } from './HistoryPane';
import { MessagePane } from './MessagePane';
import { ProfilePane } from './ProfilePane';
import { QuestionnairePane } from './QuestionnairePane';

type Props = {
  checkinEntry: CheckinEntryDetailFragment;
};

type TabType = (typeof TabMap)[keyof typeof TabMap];

export const CheckinEntryKarte = React.memo((props: Props) => {
  const { checkinEntry } = props;
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);
  const [tab, setTab] = useState<TabType>('reception');

  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] as TabType;

      setTab(newTab);
    },
    [setTab],
  );

  return hasEnabledFollowup ? (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab === TabMap.reception}
          data-tab={TabMap.reception}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.history}
          data-tab={TabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.questionnaire}
          data-tab={TabMap.questionnaire}
          onClick={handleChange}
          size="xs"
        >
          問診
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.message}
          data-tab={TabMap.message}
          onClick={handleChange}
          size="xs"
        >
          メッセージ
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.file}
          data-tab={TabMap.file}
          onClick={handleChange}
          size="xs"
        >
          ファイル
        </Tabs.Tab>
      </Tabs>
      {tab === TabMap.reception && <ProfilePane checkinEntry={checkinEntry} />}
      {tab === TabMap.history && <HistoryPane />}
      {tab === TabMap.questionnaire && <QuestionnairePane />}
      {tab === TabMap.message && <MessagePane />}
      {tab === TabMap.file && <FilePane />}
    </Grid>
  ) : (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab === TabMap.reception}
          data-tab={GuestPatientTabMap.profile}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.history}
          data-tab={GuestPatientTabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab === TabMap.questionnaire}
          data-tab={GuestPatientTabMap.questionnaire}
          onClick={handleChange}
          size="xs"
        >
          問診
        </Tabs.Tab>
      </Tabs>
      {tab === TabMap.reception && <ProfilePane checkinEntry={checkinEntry} />}
      {tab === TabMap.history && <HistoryPane />}
      {tab === TabMap.questionnaire && <QuestionnairePane />}
    </Grid>
  );
});

CheckinEntryKarte.displayName = 'CheckinEntryKarte';
