import { useCallback, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { GetCheckinEntryDetailDocument, useNotifyCheckinEntryMutation } from '~/graphql';
import { checkinNotifyConfirmModalState } from '~/state/reception/atoms';

export const useNotifyCheckinEntry = () => {
  const [error, setError] = useState('');
  const { checkinEntryId } = useRecoilValue(checkinNotifyConfirmModalState);

  const resetState = useResetRecoilState(checkinNotifyConfirmModalState);
  const [notifyCheckinEntry, { loading: notifying }] = useNotifyCheckinEntryMutation({
    onCompleted: () => {
      resetState();
    },
    refetchQueries: [{ query: GetCheckinEntryDetailDocument, variables: { id: checkinEntryId } }],
  });

  const notify = useCallback(async () => {
    if (!checkinEntryId) return;

    setError('');

    try {
      await notifyCheckinEntry({
        variables: {
          input: {
            checkinEntryId: checkinEntryId,
          },
        },
      });
    } catch (e) {
      setError(e?.message || 'エラーが発生しました');
    }
  }, [checkinEntryId, notifyCheckinEntry]);

  return { notify, notifying, error };
};
