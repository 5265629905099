import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Box, Drawer, Grid } from '~/components/blocks';
import { PatientKarte, PatientProfile } from '~/components/partials';

import { Footer } from '../Footer';
import { useMarkNotification } from './use-mark-notification';

type Props = {
  open: boolean;
  draftAppointmentId: string | null;
  patientId: string | null;
  onClose: () => void;
  onTransitionEnd: () => void;
};

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const ProfileDrawer = (props: Props) => {
  const { onClose, onTransitionEnd } = props;

  useMarkNotification(props.draftAppointmentId);

  return (
    <Drawer open={props.open} onClose={onClose} onTransitionEnd={onTransitionEnd}>
      {props.draftAppointmentId && props.patientId && (
        <Box position="relative" overflow="hidden" height="100%">
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <PatientProfile appointmentId={null} patientId={props.patientId} />
            </ProfileBox>
            <Box overflow="auto">
              <PatientKarte
                draftAppointmentId={props.draftAppointmentId}
                patientId={props.patientId}
              />
            </Box>
            <Footer draftAppointmentId={props.draftAppointmentId} />
          </Grid>
        </Box>
      )}
    </Drawer>
  );
};
