import { useEffect } from 'react';

import {
  DraftAppointmentOnFooterFragment,
  useGetDraftAppointmentOnFooterLazyQuery,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';

export const useFetchDraftAppointment = (
  draftAppointmentId: string,
): { draftAppointment: DraftAppointmentOnFooterFragment | undefined } => {
  const [event, clear] = useSubscriptions('DraftAppointment', ['updated']);

  const [getDraftAppointment, { data }] = useGetDraftAppointmentOnFooterLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (event) {
        clear();
      }
    },
  });

  const draftAppointment = getNode(data, 'DraftAppointment');

  useEffect(() => {
    getDraftAppointment({ variables: { draftAppointmentId } });
  }, [draftAppointmentId, getDraftAppointment]);

  useEffect(() => {
    if (event?.actionName === 'updated' && event.typeId === draftAppointmentId) {
      getDraftAppointment({ variables: { draftAppointmentId } });
    }
  }, [draftAppointmentId, event?.actionName, event?.typeId, getDraftAppointment]);

  return { draftAppointment };
};
