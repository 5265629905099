import styled from '@emotion/styled';
import React from 'react';

import { Text } from '~/components/blocks';

type Props = {
  totalCount: number;
};

const CountBox = styled('span')(({ theme }) => ({
  fontSize: theme.fontSizes.l,
  paddingRight: theme.space.xs,
}));

export const IncompleteCount = (props: Props) => {
  const { totalCount } = props;

  return (
    <Text size="s" fontWeight="bold">
      未完了：<CountBox>{totalCount}</CountBox>件
    </Text>
  );
};
