import { useRef, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { useGetDirectVisitorsQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useScrollToTop } from '~/hooks/use-scroll-to-top';
import { newReceptionDrawerState } from '~/state/reception/atoms';
import { NewReceptionPanelMap } from '~/state/reception/types';

import { GuestPatientForm } from '../../GuestPatientForm';
import { Fields } from '../../GuestPatientForm/types';

export const DEFAULT_PER_PAGE = 3;

export const useSubmitDirectVisitor = () => {
  const formRef = useRef<RefAttributeType<typeof GuestPatientForm>>(null);
  const [containerRef, scrollToTop] = useScrollToTop<HTMLDivElement>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // FIXME: LazyQueryではasync・awaitが使えないため、通常のQueryのrefetch利用
  // apolloのバージョンアップで使えるようになったら、use-fetch-direct_visitors.tsへ置き換え
  const { refetch } = useGetDirectVisitorsQuery({
    skip: !isSubmitting,
    variables: {
      phoneNumber: '',
      page: 1,
      perPage: DEFAULT_PER_PAGE,
    },
  });

  const handleSubmit = useRecoilCallback(
    ({ set }) =>
      async (values: Fields) => {
        setIsSubmitting(true);
        setError(null);

        try {
          const directVisitorList = await refetch({
            phoneNumber: values.mobilePhone,
          });

          const totalVisitorsWithSamePhoneNumber =
            getMe(directVisitorList.data)?.organization.directVisitors.nodesCount ?? 0;

          set(newReceptionDrawerState, (_state) => ({
            ..._state,
            currentPanel: NewReceptionPanelMap.confirm,
            inputValue: values,
            perPage: DEFAULT_PER_PAGE,
            existingPhoneNumber: totalVisitorsWithSamePhoneNumber > 0,
          }));

          formRef.current?.resetForm();
          scrollToTop();
        } catch (error) {
          setError(error?.message || 'エラーが発生しました');
          scrollToTop();
        }
        setIsSubmitting(false);
      },
    [refetch, scrollToTop],
  );

  return {
    isSubmitting,
    error,
    containerRef,
    formRef,
    handleSubmit,
  };
};
