import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Grid, Tabs } from '~/components/blocks';
import { GuestPatientTabMap } from '~/constants/tab';
import { PlanFeatureCode, VisitDetailFragment } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { guestPatientTabState } from '~/state/reception/atoms';
import { TabType } from '~/state/reception/types';

import { FilePane } from './FilePane';
import { HistoryPane } from './HistoryPane';
import { MessagePane } from './MessagePane';
import { ProfilePane } from './ProfilePane';
import { QuestionnairePane } from './QuestionnairePane';

type Props = {
  visit: VisitDetailFragment;
};

export const GuestPatientKarte = React.memo((props: Props) => {
  const { visit } = props;
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);
  const [tab, setTab] = useRecoilState(guestPatientTabState);

  const guestPatient = visit.directVisitor;

  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] as TabType;

      setTab((_state) => ({ ..._state, type: newTab }));
    },
    [setTab],
  );

  return hasEnabledFollowup ? (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab.type === GuestPatientTabMap.profile}
          data-tab={GuestPatientTabMap.profile}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === GuestPatientTabMap.history}
          data-tab={GuestPatientTabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === GuestPatientTabMap.questionnaire}
          data-tab={GuestPatientTabMap.questionnaire}
          onClick={handleChange}
          size="xs"
        >
          問診
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === GuestPatientTabMap.message}
          data-tab={GuestPatientTabMap.message}
          onClick={handleChange}
          size="xs"
        >
          メッセージ
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === GuestPatientTabMap.file}
          data-tab={GuestPatientTabMap.file}
          onClick={handleChange}
          size="xs"
        >
          ファイル
        </Tabs.Tab>
      </Tabs>
      {tab.type === GuestPatientTabMap.profile && <ProfilePane visit={props.visit} />}
      {tab.type === GuestPatientTabMap.history && <HistoryPane guestPatientId={guestPatient.id} />}
      {tab.type === GuestPatientTabMap.questionnaire && <QuestionnairePane />}
      {tab.type === GuestPatientTabMap.message && <MessagePane guestPatientId={guestPatient.id} />}
      {tab.type === GuestPatientTabMap.file && <FilePane guestPatientId={guestPatient.id} />}
    </Grid>
  ) : (
    <Grid height="100%" gridTemplateRows="min-content 1fr" gridAutoColumns="1fr">
      <Tabs>
        <Tabs.Tab
          attached
          active={tab.type === GuestPatientTabMap.profile}
          data-tab={GuestPatientTabMap.profile}
          onClick={handleChange}
          size="xs"
        >
          受付
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === GuestPatientTabMap.history}
          data-tab={GuestPatientTabMap.history}
          onClick={handleChange}
          size="xs"
        >
          履歴
        </Tabs.Tab>
        <Tabs.Tab
          attached
          active={tab.type === GuestPatientTabMap.questionnaire}
          data-tab={GuestPatientTabMap.questionnaire}
          onClick={handleChange}
          size="xs"
        >
          問診
        </Tabs.Tab>
      </Tabs>
      {tab.type === GuestPatientTabMap.profile && <ProfilePane visit={props.visit} />}
      {tab.type === GuestPatientTabMap.history && <HistoryPane guestPatientId={guestPatient.id} />}
      {tab.type === GuestPatientTabMap.questionnaire && <QuestionnairePane />}
    </Grid>
  );
});

GuestPatientKarte.displayName = 'GuestPatientKarte';
