import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { useGetIncompleteTaskCountLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { useSubscriptions } from '~/hooks/use-subscriptions';
import { receptionPageInfoState } from '~/state/reception/atoms';

export const useFetchIncompleteTaskCount = () => {
  const setState = useSetRecoilState(receptionPageInfoState);
  const [getIncompleteTaskCount, { data, loading }] = useGetIncompleteTaskCountLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [event] = useSubscriptions('Appointment', ['created', 'updated', 'deleted']);

  const organization = data && getMe(data)?.organization;
  const incompleteAppointmentCount = organization?.incompleteAppointmentCount || 0;
  const incompleteDraftAppointmentCount = organization?.incompleteDraftAppointmentCount || 0;
  const incompleteCheckinEntryCount = organization?.incompleteCheckinEntryCount || 0;
  const totalCount =
    incompleteAppointmentCount + incompleteDraftAppointmentCount + incompleteCheckinEntryCount;
  const handleShow = useCallback(() => {
    setState((state) => ({
      ...state,
      page: 1,
      date: null,
      statuses: ['status1', 'status2', 'status3', 'status4', 'status7'],
      patientName: null,
    }));
  }, [setState]);

  // マウント時に件数取得
  useEffect(() => {
    getIncompleteTaskCount();
  }, [getIncompleteTaskCount]);

  useEffect(() => {
    if (event) {
      getIncompleteTaskCount();
    }
  }, [event, getIncompleteTaskCount]);

  return {
    totalCount,
    loading,
    handleShow,
  };
};
