import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Button, EntryList, Modal, Text } from '~/components/blocks';
import {
  GetReceptionListAppointmentDocument,
  GetWebVisitDetailQuery,
  useCreateMessageForWebVisitorMutation,
  useGetWebVisitDetailLazyQuery,
  useSkipEncounterMutation,
  WebVisitorAppointmentDetailFragment,
  WebVisitorDetailFragment,
} from '~/graphql';
import { useOrganizationElectronicPrescription } from '~/hooks/use-organization-electronic-prescription';
import { webVisitorNotificationConfirmModalState } from '~/state/reception/atoms';

const Message = styled('header')(({ theme }) =>
  css({
    whiteSpace: 'pre-wrap',
    background: theme.colors.background.bg,
    padding: theme.space.l,
  }),
);

const getPatientName = (data?: GetWebVisitDetailQuery) => {
  if (!data?.node) return '';
  const appointment = data.node as WebVisitorAppointmentDetailFragment;
  const { familyName, givenName } = appointment.webVisitor as WebVisitorDetailFragment;

  return `${familyName}${givenName}`;
};

export const NotificationConfirmModal = () => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(webVisitorNotificationConfirmModalState);
  const [getWebVisitorProfile, { data: webVisitorData }] = useGetWebVisitDetailLazyQuery();
  const [skipEncounter, { loading: skipping }] = useSkipEncounterMutation({
    onCompleted: () => setState(() => ({ isOpen: false, appointmentId: null, webVisitorId: null })),
    refetchQueries: [
      {
        query: GetReceptionListAppointmentDocument,
        variables: { appointmentId: state.appointmentId },
      },
    ],
  });
  const [createMessage, { loading: creating, error: error }] =
    useCreateMessageForWebVisitorMutation({
      onCompleted: async () => {
        if (state.appointmentId) {
          // 指導待ち -> 未会計に変更
          await skipEncounter({
            variables: {
              input: {
                appointmentId: state.appointmentId,
              },
            },
          });
        }
      },
    });
  const { electronicPrescription } = useOrganizationElectronicPrescription();
  const patientName = getPatientName(webVisitorData);
  const content = electronicPrescription
    ? `${patientName}様\n\nお薬の受け渡し準備ができました。処方箋の原本または処方内容（控え）をお持ちのうえ薬局にお越しください。`
    : `${patientName}様\n\nお薬の受け渡し準備ができました。処方箋の原本をお持ちのうえ薬局にお越しください。`;

  const handleClose = useCallback(
    () => setState((_state) => ({ ..._state, isOpen: false, patientId: null })),
    [setState],
  );
  const handleSend = useCallback(async () => {
    if (state.webVisitorId) {
      await createMessage({
        variables: {
          input: {
            webVisitorId: state.webVisitorId,
            content,
          },
        },
      });
    }
  }, [content, createMessage, state.webVisitorId]);

  useEffect(() => {
    if (state.appointmentId) {
      getWebVisitorProfile({ variables: { id: state.appointmentId as string } });
    }
  }, [getWebVisitorProfile, state.appointmentId]);

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>準備完了通知を送信</Modal.Header>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため送信できませんでした</Alert>}
        <Text>メッセージ内容を確認のうえ、「送信」ボタンをクリックしてください。</Text>
        <EntryList mt={theme.space.m}>
          <EntryList.Head>送信メッセージ</EntryList.Head>
          <EntryList.Body>
            <Message>{content}</Message>
          </EntryList.Body>
        </EntryList>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={creating || skipping} onClick={handleSend}>
          送信
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
