import React from 'react';

import { Button } from '~/components/blocks';
import { theme } from '~/styles/theme';

type Props = {
  totalCount: number;
  onClick: () => void;
};

export const FilterIncompleteButton = (props: Props) => {
  const { totalCount } = props;

  return (
    <>
      {totalCount > 0 && (
        <Button size="s" use="base" ml={theme.space.m} onClick={props.onClick}>
          未完了のみ表示
        </Button>
      )}
    </>
  );
};
