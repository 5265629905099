// JAHISの和暦の年号(G)
// 別表２「年号区分コード」

type Year = {
  [key: string]: number;
};

const START_YEARS: Year = {
  '1': 1868, // 明治
  '2': 1912, // 大正
  '3': 1926, // 昭和
  '4': 1989, // 平成
  '5': 2019, // 令和
} as const;

export const toYearFromWareki = (str: string) => {
  const code = str[0];
  const warekiYear = str.slice(1, 3);
  const startYear = START_YEARS[code];

  if (!startYear) return;

  const year = START_YEARS[code] + +warekiYear - 1;

  return year;
};
