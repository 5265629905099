import { useMemo } from 'react';

import { DirectVisitorDetailFragment } from '~/graphql';

import { Fields } from '../../../GuestPatientForm/types';

export const useDefaultValues = (guestPatient: DirectVisitorDetailFragment) => {
  const defaultValues: Fields = useMemo(() => {
    return {
      familyName: guestPatient.familyName,
      givenName: guestPatient.givenName,
      phoneticFamilyName: guestPatient.phoneticFamilyName,
      phoneticGivenName: guestPatient.phoneticGivenName,
      birthDate: guestPatient.birthDate,
      sex: guestPatient.sex,
      mobilePhone: guestPatient.mobilePhone,
    };
  }, [guestPatient]);

  return defaultValues;
};
