import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';

import { Alert, Box, Button, Drawer, Grid, Header } from '~/components/blocks';

import { GuestPatientForm } from '../../GuestPatientForm';
import { InputForm } from '../InputForm';
import { SearchDirectVisitors } from '../SearchDirectVisitors';
import { useDefaultValues } from './use-default_values';
import { useSubmitDirectVisitor } from './use-submit-direct_visitor';

export const InputDirectVisitorPanel = () => {
  const theme = useTheme();

  const defaultValues = useDefaultValues();
  const { isSubmitting, error, containerRef, formRef, handleSubmit } = useSubmitDirectVisitor();

  const handleSend = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="auto">
      <Header attached="drawer">新患受付</Header>
      <Box ref={containerRef} overflow="auto" paddingX={theme.space.l}>
        <SearchDirectVisitors disabled={isSubmitting} />
        {error && (
          <Alert status="error" marginTop={theme.space.l} whiteSpace="pre-wrap">
            {error}
          </Alert>
        )}
        <InputForm marginTop={theme.space.l}>
          <GuestPatientForm
            ref={formRef}
            disabled={isSubmitting}
            initialValues={defaultValues}
            onSubmit={handleSubmit}
          />
        </InputForm>
      </Box>
      <Drawer.Footer>
        <Button use="base" wide="fill" loading={isSubmitting} onClick={handleSend}>
          受付
        </Button>
      </Drawer.Footer>
    </Grid>
  );
};
