import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { newReceptionDrawerState } from '~/state/reception/atoms';

import { Fields } from '../../GuestPatientForm/types';

export const useDefaultValues = () => {
  const { inputValue, qrCodePatient } = useRecoilValue(newReceptionDrawerState);

  const defaultValues: Fields = useMemo(() => {
    if (qrCodePatient) {
      return {
        ...qrCodePatient,
        mobilePhone: inputValue?.mobilePhone || '',
      };
    }
    if (inputValue) {
      return inputValue;
    } else {
      return {
        familyName: '',
        givenName: '',
        phoneticFamilyName: '',
        phoneticGivenName: '',
        birthDate: '',
        sex: 'male',
        mobilePhone: '',
      };
    }
  }, [inputValue, qrCodePatient]);

  return defaultValues;
};
