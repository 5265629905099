import { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import {
  CancelAppointmentReason,
  GetUnreadAppointmentsNotificationsDocument,
  useCancelAppointmentWithWebVisitorMutation,
} from '~/graphql';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';

export const useCancelAppointment = (appointmentId: string) => {
  const [isCanceling, setIsCanceling] = useState(false);

  const [cancel] = useCancelAppointmentWithWebVisitorMutation({
    refetchQueries: [{ query: GetUnreadAppointmentsNotificationsDocument }],
  });

  const handleCancel = useRecoilCallback(
    ({ set }) =>
      async (reason: CancelAppointmentReason) => {
        setIsCanceling(true);
        set(profileDrawerState, (_state) => ({ ..._state, error: null }));

        try {
          await cancel({
            variables: {
              input: {
                appointmentId,
                reason,
              },
            },
          });
        } catch (_error) {
          set(profileDrawerState, (_state) => ({
            ..._state,
            error: _error?.message || 'エラーが発生しました',
          }));
        } finally {
          setIsCanceling(false);
        }
      },
    [appointmentId, cancel],
  );

  return {
    canceling: isCanceling,
    handleCancel,
  };
};
