import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Dialog, Flex } from '~/components/blocks';
import { prescriptionQrCodeReaderDialogState } from '~/state/reception/atoms';

import { QrCodeReader } from './QrCodeReader';

export const QrCodeReaderDialog = () => {
  const theme = useTheme();
  const { isOpen } = useRecoilValue(prescriptionQrCodeReaderDialogState);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(prescriptionQrCodeReaderDialogState);
      },
    [],
  );

  return (
    <Dialog invert open={isOpen} onClose={handleClose}>
      <Dialog.Header>処方箋QRコードの読み取り</Dialog.Header>
      <Dialog.Body>
        <Flex justifyContent="center" width="100%" marginTop={theme.space.m}>
          <QrCodeReader />
        </Flex>
      </Dialog.Body>
    </Dialog>
  );
};
