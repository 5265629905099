import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  GetWebVisitDetailQuery,
  useGetWebVisitDetailLazyQuery,
  WebVisitorAppointmentDetailFragment,
  WebVisitorDetailFragment,
} from '~/graphql';
import { webVisitorNotificationCongestionModalState } from '~/state/reception/atoms';

const getPatientName = (data?: GetWebVisitDetailQuery) => {
  if (!data?.node) return '';
  const appointment = data.node as WebVisitorAppointmentDetailFragment;
  const { familyName, givenName } = appointment.webVisitor as WebVisitorDetailFragment;

  return `${familyName}${givenName}`;
};

export const useFetchWebVisitorName = () => {
  const { appointmentId } = useRecoilValue(webVisitorNotificationCongestionModalState);
  const [getWebVisitorProfile, { data: webVisitorData }] = useGetWebVisitDetailLazyQuery();
  const patientName = getPatientName(webVisitorData);

  useEffect(() => {
    if (appointmentId) {
      getWebVisitorProfile({ variables: { id: appointmentId } });
    }
  }, [getWebVisitorProfile, appointmentId]);

  return { patientName };
};
