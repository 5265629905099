import { useEffect } from 'react';

import {
  useGetDraftAppointmentUnreadOrganizationNotificationLazyQuery,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { getNode } from '~/graphql/utility';

export const useMarkNotification = (draftAppointmentId: string | null) => {
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const [getNotifications] = useGetDraftAppointmentUnreadOrganizationNotificationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const notifications =
        getNode(_data, 'DraftAppointment')?.unreadOrganizationNotifications || [];

      for (const notification of notifications) {
        markNotification({
          variables: {
            input: {
              organizationNotificationId: notification.id,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (draftAppointmentId) {
      getNotifications({
        variables: {
          draftAppointmentId,
        },
      });
    }
  }, [draftAppointmentId, getNotifications]);
};
