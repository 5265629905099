import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Button, Checkbox } from '~/components/blocks';
import { b2CloudDownloadModalState } from '~/state/reception/atoms';

import { useGenerateCsv } from './use-generate-csv';

type Props = {
  isEnabled: boolean;
  notFound: boolean;
};

export const Footer = React.memo((props: Props) => {
  const theme = useTheme();
  const { isEnabled, notFound } = props;
  const [{ isOpen, disabled }, setState] = useRecoilState(b2CloudDownloadModalState);
  const [willFinished, setWillFinished] = useState(true);

  const generate = useGenerateCsv();

  const handleClick = useCallback(() => {
    if (!isEnabled) {
      setState((_state) => ({
        ..._state,
        error: 'B2クラウド設定が無効のため、ダウンロードできません',
      }));
      return;
    }

    generate(willFinished);
  }, [generate, isEnabled, setState, willFinished]);

  useEffect(() => {
    if (isOpen) {
      setWillFinished(true);
    }
  }, [isOpen]);

  return (
    <>
      <Checkbox
        disabled={disabled || notFound}
        checked={willFinished}
        onChange={() => setWillFinished(!willFinished)}
        label="選択した患者のステータスを完了にする"
      />
      <Button
        use="base"
        marginLeft={theme.space.m}
        disabled={notFound}
        loading={disabled}
        onClick={handleClick}
      >
        CSVダウンロード
      </Button>
    </>
  );
});

Footer.displayName = 'Footer';
