import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { Button } from '~/components/blocks';
import {
  GetUnreadAppointmentsNotificationsDocument,
  useFinishAppointmentMutation,
  WebVisitorAppointmentDetailFragment,
} from '~/graphql';
import { webVisitorProfileDrawerState } from '~/state/reception/atoms';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
};

export const ChargedButtons = (props: Props) => {
  const setDrawerState = useSetRecoilState(webVisitorProfileDrawerState);

  // 会計済(配送待ち) -> 完了
  const [finishAppointment, { loading: finishing }] = useFinishAppointmentMutation({
    onCompleted: () => setDrawerState((_state) => ({ ..._state, error: null })),
    onError: (error) => setDrawerState((_state) => ({ ..._state, error: error.message })),
    refetchQueries: [{ query: GetUnreadAppointmentsNotificationsDocument }],
  });
  const handleFinish = useCallback(() => {
    finishAppointment({
      variables: {
        input: {
          appointmentId: props.appointment.id,
        },
      },
    });
  }, [finishAppointment, props.appointment.id]);

  return (
    <Button use="base" wide="fill" size="l" loading={finishing} onClick={handleFinish}>
      完了
    </Button>
  );
};
