import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Button, EntryList, Modal, Text } from '~/components/blocks';
import { webVisitorNotificationCongestionModalState } from '~/state/reception/atoms';

import { useFetchWebVisitorName } from './use-fetch-web-visitor-name';
import { useSendCongestionNotification } from './use-send-congestion-notification';

const Message = styled('header')(({ theme }) =>
  css({
    whiteSpace: 'pre-wrap',
    background: theme.colors.background.bg,
    padding: theme.space.l,
  }),
);

export const NotificationCongestionModal = () => {
  const theme = useTheme();
  const { isOpen } = useRecoilValue(webVisitorNotificationCongestionModalState);
  const { loading, error, handleSend } = useSendCongestionNotification();
  const { patientName } = useFetchWebVisitorName();

  const content = `${patientName}様\n\n現在薬局が混み合っており、お薬の用意に時間がかかっています。申し訳ありませんが、薬局からの連絡を今しばらくお待ちください`;

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(webVisitorNotificationCongestionModalState);
      },
    [],
  );

  return (
    <Modal open={isOpen} size="s" onClose={handleClose}>
      <Modal.Header>混雑時通知を送信</Modal.Header>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため送信できませんでした</Alert>}
        <Text>
          メッセージ内容を確認のうえ、「送信」ボタンをクリックしてください。本メッセージは1回まで送信できます。
        </Text>
        <EntryList mt={theme.space.m}>
          <EntryList.Head>送信メッセージ</EntryList.Head>
          <EntryList.Body>
            <Message>{content}</Message>
          </EntryList.Body>
        </EntryList>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={loading} onClick={() => handleSend(content)}>
          送信
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
