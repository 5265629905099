import { useCallback, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import {
  GetReceptionPaneAppointmentDocument,
  UberUndeliverableReasonStatus,
  useCreateUberDeliveryMutation,
} from '~/graphql';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';

export const useRequestUberDelivery = () => {
  const { appointmentId, quoteId, autoMode } = useRecoilValue(requestUberDeliveryModalState);
  const resetState = useResetRecoilState(requestUberDeliveryModalState);
  const [error, setError] = useState<string | null>(null);
  const [requestUberDelivery, { loading }] = useCreateUberDeliveryMutation({
    refetchQueries: [{ query: GetReceptionPaneAppointmentDocument, variables: { appointmentId } }],
  });

  const create = useCallback(
    async (pieces: Array<number | ''>) => {
      setError(null);

      try {
        if (appointmentId && quoteId) {
          await requestUberDelivery({
            variables: {
              input: {
                appointmentId,
                quoteId,
                autoMode: autoMode === 'delivered' || autoMode === 'canceled',
                undeliverableReason:
                  autoMode === 'canceled'
                    ? UberUndeliverableReasonStatus.CannotFindCustomerAddress
                    : null,
                quantities: pieces.filter((v) => v !== null) as number[],
              },
            },
          });
          resetState();
        }
      } catch (e) {
        const message = e?.message || 'エラーが発生しました';
        setError(message);
      }
    },
    [appointmentId, quoteId, requestUberDelivery, autoMode, resetState],
  );

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  return { create, loading, error, resetError };
};
