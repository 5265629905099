import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import {
  useGetCheckinUnreadOrganizationNotificationLazyQuery,
  useMarkOrganizationNotificationMutation,
} from '~/graphql';
import { getNode } from '~/graphql/utility';
import { checkinEntryProfileDrawerState } from '~/state/reception/atoms';

export const useMarkNotification = () => {
  const { checkinEntryId } = useRecoilValue(checkinEntryProfileDrawerState);
  const [markNotification] = useMarkOrganizationNotificationMutation();
  const [getNotifications] = useGetCheckinUnreadOrganizationNotificationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const notifications = getNode(_data, 'CheckinEntry')?.unreadOrganizationNotifications || [];
      for (const notification of notifications) {
        markNotification({
          variables: {
            input: {
              organizationNotificationId: notification.id,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (checkinEntryId) {
      getNotifications({
        variables: {
          checkinEntryId,
        },
      });
    }
  }, [checkinEntryId, getNotifications]);
};
