import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Box } from '~/components/blocks';

export const HistoryPane = () => {
  const theme = useTheme();
  return (
    <Box padding={theme.space.l}>
      <Alert status="info">チェックインで登録された患者のため、履歴はありません</Alert>
    </Box>
  );
};
