import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Flex, Text } from '~/components/blocks';

type Props = {
  index: number;
  value: number | '';
};

const CustomFlex = styled(Flex)(() =>
  css({
    flexDirection: 'column',
  }),
);
export const ContentItem = (props: Props) => {
  const { value } = props;

  return (
    <CustomFlex>
      <Text size="xs" color="grey01">
        梱包物の数
      </Text>
      <Text size="l">{value}つ</Text>
    </CustomFlex>
  );
};
