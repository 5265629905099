import { useTheme } from '@emotion/react';
import React from 'react';

import { Box, Grid, ScrollBox } from '~/components/blocks';

import { History } from './History';
import { HistoryPagination } from './HistoryPagination';
import { useFetchHistories } from './use-fetch-histories';

type Props = {
  guestPatientId: string;
};

export const HistoryPane = React.memo((props: Props) => {
  const theme = useTheme();
  const { scrollRef, loading, visits } = useFetchHistories(props.guestPatientId);

  return (
    <Grid
      height="100%"
      gridTemplateRows="1fr min-content"
      gridTemplateColumns="1fr"
      padding={theme.space.l}
    >
      <ScrollBox ref={scrollRef} loading={loading}>
        <Box>
          {visits.map((visit) => (
            <History key={visit.id} visit={visit} />
          ))}
        </Box>
      </ScrollBox>
      <HistoryPagination />
    </Grid>
  );
});

HistoryPane.displayName = 'HistoryPane';
