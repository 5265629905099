import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Tooltip } from '~/components/blocks';
import { CheckinEntryItemFragment, CheckinEntryStatus } from '~/graphql';

import { CheckinEntry } from './CheckinEntry';

type Props = {
  disabled: boolean;
  radioName?: string;
  radioValue: string | null;
  checkinEntry: CheckinEntryItemFragment;
  onClick: (checkinEntryId: string) => void;
};

const TooltipItem = styled('div')(({ theme }) => css({ padding: theme.space.m }));

export const CheckinEntryItem = React.memo((props: Props) => {
  const { checkinEntry } = props;

  return (
    <>
      {checkinEntry.status === CheckinEntryStatus.Finished ? (
        <CheckinEntry {...props} />
      ) : (
        <Tooltip
          content={
            <TooltipItem>
              店舗チェックインが完了していないため、
              <br />
              新患受付できません。
              <br />
              店舗チェックインを完了してください
            </TooltipItem>
          }
        >
          <div>
            <CheckinEntry {...props} disabled={true} />
          </div>
        </Tooltip>
      )}
    </>
  );
});

CheckinEntryItem.displayName = 'CheckinEntryItem';
