import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Interview } from '~/components/partials';

type Props = {
  children: React.ReactNode;
};

const CustomIcon = styled(Interview.Icon)(({ theme }) =>
  css({
    borderColor: theme.colors.text.primary,
  }),
);

export const CustomQuestionTitle = (props: Props) => {
  const { children } = props;

  return (
    <Interview.Title>
      <CustomIcon>Q</CustomIcon>
      {children}
    </Interview.Title>
  );
};
