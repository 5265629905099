import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import React, { useCallback, useRef, useState } from 'react';
import { useResetRecoilState } from 'recoil';
import { compose, space, SpaceProps } from 'styled-system';

import { Alert, Button, Drawer, Flex, Grid, Icon } from '~/components/blocks';
import { Fields } from '~/components/pages/Reception/GuestPatientForm/types';
import { useCreateDirectVisitor } from '~/components/pages/Reception/NewReceptionDrawer/use-create-direct_visitor';
import { CheckinEntryItemFragment } from '~/graphql';
import { checkinEntryProfileDrawerState } from '~/state/reception/atoms';

import { DirectVisitorForm } from '../CheckinEntryProfileDrawer/DirectVisitorForm';
import { useDefaultValues } from './use-default_values';

type Props = {
  checkinEntry: CheckinEntryItemFragment;
  onBackClick: () => void;
};

const InputFormRoot = styled('div', {
  shouldForwardProp,
})<SpaceProps>(
  ({ theme }) =>
    css({
      backgroundColor: theme.colors.background.bg,
      border: theme.borders.default,
      borderRadius: theme.radii.default,
      marginTop: theme.space.l,
      padding: `calc(${theme.space.s} + ${theme.space.m}) + ${theme.space.l}`,
      input: {
        backgroundColor: theme.colors.background.default,
        border: theme.borders.default,
      },
    }),
  compose(space),
);

export const CheckinEntryNewReceptionPanel = (props: Props) => {
  const theme = useTheme();
  const formRef = useRef<RefAttributeType<typeof DirectVisitorForm>>(null);
  const { checkinEntry, onBackClick } = props;
  const [error, setError] = useState('');
  const resetState = useResetRecoilState(checkinEntryProfileDrawerState);

  const initialValues = useDefaultValues(checkinEntry);
  const { create, creating } = useCreateDirectVisitor();

  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        await create(values);
        resetState();
      } catch (e) {
        setError(e);
      }
    },
    [create, resetState],
  );

  const handleSendClick = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  return (
    <>
      <Grid
        height="100%"
        gridTemplateRows={
          error
            ? 'min-content min-content min-content min-content'
            : 'min-content min-content min-content'
        }
        gridAutoColumns="1fr"
        padding={theme.space.l}
        overflow="auto"
      >
        {error && (
          <Alert status="error" marginBottom={theme.space.l}>
            {error}
          </Alert>
        )}
        <Alert status="info">
          チェックイン情報を利用して新患受付します。正しいデータになるようフォームを補完してください
        </Alert>
        <InputFormRoot>
          <DirectVisitorForm ref={formRef} onSubmit={handleSubmit} initialValues={initialValues} />
        </InputFormRoot>
        <Flex justifyContent="flex-start" marginTop={theme.space.l}>
          <Button size="s" use="white" onClick={onBackClick}>
            <Icon icon="back-line" size="s" />
            戻る
          </Button>
        </Flex>
      </Grid>
      <Drawer.Footer>
        <Button use="base" wide="fill" size="l" onClick={handleSendClick} loading={creating}>
          登録
        </Button>
      </Drawer.Footer>
    </>
  );
};
