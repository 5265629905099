import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { rgba } from 'polished';
import React, { useCallback, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { DateLabel, Flex, Icon, Name, Table, Tag, Text } from '~/components/blocks';
import { CheckinEntryStatusLabel, ReceptionTypeIcon } from '~/components/partials';
import { ReceptionCheckinEntryFragment } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  guestPatientProfileDrawerState,
  newReceptionDrawerState,
  receptionPageInfoState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

type Props = {
  activeMedixs: boolean;
  updated: boolean;
  checkinEntry: ReceptionCheckinEntryFragment;
  onAnimationEnd: (visitId: string) => void;
};

const animation = keyframes`
    from {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    to {
        opacity: 1;
    }
`;

const TableRow = styled(Table.Tr, { shouldForwardProp })<{ updated: boolean }>(
  ({ theme, updated }) =>
    css({
      animation: updated ? `${animation} 1s ease 1` : 'none',
      '&:hover': {
        backgroundColor: rgba(theme.colors.background.default, 0.5),
        boxShadow: `inset 3px 0 0 ${theme.colors.background.primary}`,
      },
    }),
);

export const CheckinEntry = React.memo((props: Props) => {
  const { checkinEntry, onAnimationEnd } = props;
  const { date } = useRecoilValue(receptionPageInfoState);
  const { enable: enabledUber } = useUberOrganizationSetting();

  const enteredAt = checkinEntry.enteredAt;
  const enteredEndAt = new Date(+new Date(enteredAt) + 30 * 60 * 1000);

  const memos = useMemo(
    () => checkinEntry.description.split(',').filter(Boolean),
    [checkinEntry.description],
  );

  const handleAnimationEnd = useCallback(
    () => onAnimationEnd(checkinEntry.id),
    [onAnimationEnd, checkinEntry.id],
  );
  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        set(checkinEntryProfileDrawerState, (_state) => ({
          ..._state,
          isOpen: true,
          checkinEntryId: checkinEntry.id,
        }));

        reset(draftAppointmentPatientProfileDrawerState);
        reset(profileDrawerState);
        reset(newReceptionDrawerState);
        reset(webVisitorProfileDrawerState);
        reset(guestPatientProfileDrawerState);
      },
    [checkinEntry.id],
  );

  return (
    <TableRow updated={props.updated} onClick={handleClick} onAnimationEnd={handleAnimationEnd}>
      <Table.Td nowrap>
        <Flex alignItems="center">
          <DateLabel>
            {!date && <DateLabel.Time>{Label.YYYYMMDDja(enteredAt)}</DateLabel.Time>}
            <DateLabel.Day>
              {Label.HHMM(enteredAt)}-{Label.HHMM(enteredEndAt)}
            </DateLabel.Day>
          </DateLabel>
          <Icon icon="timer" color="blue" size="l" />
        </Flex>
      </Table.Td>
      <Table.Td>
        <CheckinEntryStatusLabel status={checkinEntry.checkinStatus} />
      </Table.Td>
      {props.activeMedixs && <Table.Td nowrap />}
      <Table.Td>
        <Name
          familyName={checkinEntry.familyName}
          givenName={checkinEntry.givenName}
          phoneticFamilyName={checkinEntry.phoneticFamilyName}
          phoneticGivenName={checkinEntry.phoneticGivenName}
        />
      </Table.Td>
      <Table.Td>-</Table.Td>
      <Table.Td>
        <Flex alignItems="center">
          <ReceptionTypeIcon />
          <Text fontWeight="bold" size="m" whiteSpace="nowrap">
            対面
          </Text>
        </Flex>
      </Table.Td>
      {enabledUber && <Table.Td>-</Table.Td>}
      <Table.Td>
        <Text size="m" whiteSpace="nowrap">
          {Label.YYYYMMDDja(enteredAt)}&nbsp;{Label.HHMM(enteredAt)}
        </Text>
      </Table.Td>
      <Table.Td>
        {memos.map((memo, idx) => (
          <Tag key={idx} whiteSpace="nowrap">
            {memo}
          </Tag>
        ))}
      </Table.Td>
    </TableRow>
  );
});

CheckinEntry.displayName = 'CheckinEntry';
