import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Button, EntryList, Modal, Text } from '~/components/blocks';
import { CheckinEntryDetailFragment } from '~/graphql';
import { getOrganizationName } from '~/localstrage/organization_name';
import { checkinNotifyConfirmModalState } from '~/state/reception/atoms';

import { useGetCheckinEntryProfile } from './use-get_checkin_entry_profile';
import { useNotifyCheckinEntry } from './use-notify_checkin_entry';

const Message = styled('header')(({ theme }) =>
  css({
    whiteSpace: 'pre-wrap',
    background: theme.colors.background.bg,
    padding: theme.space.l,
  }),
);

const getPatientName = (checkinEntry?: CheckinEntryDetailFragment) => {
  if (!checkinEntry) return '';
  const { familyName, givenName } = checkinEntry;
  return `${familyName}${givenName}`;
};

export const NotificationConfirmModal = () => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(checkinNotifyConfirmModalState);

  const { checkinEntry, loading, error } = useGetCheckinEntryProfile();
  const { notify, notifying, error: notifyError } = useNotifyCheckinEntry();

  const patientName = getPatientName(checkinEntry);
  const organizationName = getOrganizationName();
  const content = `${patientName}様\n\n${organizationName}です。お薬の受け渡し準備が出来ましたので、薬局にお越しください。`;

  const handleClose = useCallback(
    () => setState((_state) => ({ ..._state, isOpen: false, checkinEntryId: null })),
    [setState],
  );
  const handleSend = useCallback(async () => {
    await notify();
  }, [notify]);

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>準備完了通知を送信</Modal.Header>
      <Modal.Body>
        {(error || notifyError) && (
          <Alert mb={theme.space.l}>エラーが発生したため送信できませんでした</Alert>
        )}
        <Text>メッセージ内容を確認のうえ、「送信」ボタンをクリックしてください。</Text>
        <EntryList mt={theme.space.m}>
          <EntryList.Head>送信メッセージ</EntryList.Head>
          <EntryList.Body>
            <Message>{content}</Message>
          </EntryList.Body>
        </EntryList>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={loading || notifying} onClick={handleSend}>
          送信
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
