import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Alert, Box, Drawer, Flex } from '~/components/blocks';
import { DraftAppointmentOnFooterFragment } from '~/graphql';
import {
  cancelDraftAppointmentPane,
  draftAppointmentPatientProfileDrawerState,
} from '~/state/reception/atoms';

import { CancelPane } from '../CancelPane';
import { CancelButton } from './CancelButton';
import { FinishButton } from './FinishButton';
import { RequestBookingButton } from './RequestBookingButton';
import { useFetchDraftAppointment } from './use-fetch-draft-appointment';

type Props = {
  draftAppointmentId: string;
};

const FinishButtonWrapper = styled(Box)(({ theme }) =>
  css({
    flexBasis: '30%',
    marginRight: theme.space.m,
  }),
);

const FooterParent = (
  props: React.PropsWithChildren<{ draftAppointment: DraftAppointmentOnFooterFragment }>,
) => {
  const state = useRecoilValue(draftAppointmentPatientProfileDrawerState);
  const theme = useTheme();

  return (
    <>
      <Drawer.Footer>
        {state.error && (
          <Alert status="error" marginBottom={theme.space.l}>
            {state.error}
          </Alert>
        )}
        <Flex flexDirection="column" justifyContent="center">
          {props.children}
        </Flex>
      </Drawer.Footer>
      <CancelPane draftAppointment={props.draftAppointment} />
    </>
  );
};

export const Footer = React.memo((props: Props) => {
  const { draftAppointment } = useFetchDraftAppointment(props.draftAppointmentId);
  const resetCancelPane = useResetRecoilState(cancelDraftAppointmentPane);
  const prescriptionImageCount =
    draftAppointment?.pfDispensingRequest?.prescriptionImages?.length || 0;
  // available、かつ処方箋画像が登録されている場合は処方箋は未送信のため、日時指定依頼ボタンを無効化する
  const hasPrescriptions = prescriptionImageCount > 0;

  useEffect(() => {
    resetCancelPane();
  }, [resetCancelPane]);

  if (!draftAppointment) {
    return null;
  }

  if (draftAppointment.status === 'available') {
    return (
      <FooterParent draftAppointment={draftAppointment}>
        <Flex alignItems="center" justifyContent="center" position="relative">
          {hasPrescriptions ? (
            <FinishButton />
          ) : (
            <>
              <FinishButtonWrapper>
                <FinishButton />
              </FinishButtonWrapper>
              <RequestBookingButton />
            </>
          )}
        </Flex>
        <CancelButton />
      </FooterParent>
    );
  }

  if (draftAppointment.status === 'wait_for_booking') {
    return (
      <FooterParent draftAppointment={draftAppointment}>
        <FinishButton />
        <CancelButton />
      </FooterParent>
    );
  }

  return null;
});

Footer.displayName = 'Footer';
