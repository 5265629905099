import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Button, Modal, Text } from '~/components/blocks';
import { SimplePatientProfile } from '~/components/partials';
import { finishDraftAppointmentModalState } from '~/state/reception/atoms';

import { useFinishDraftAppointment } from './use-finish-draft-appointment';

type Props = {
  draftAppointmentId: string;
  patientId: string | null;
};

export const FinishDraftAppointmentModal = (props: Props) => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(finishDraftAppointmentModalState);
  const handleClose = useCallback(() => setState(() => ({ isOpen: false })), [setState]);

  const { loading, error, handleFinish } = useFinishDraftAppointment({
    draftAppointmentId: props.draftAppointmentId,
  });

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>
        <Text size="l">服薬指導を完了します</Text>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため完了できませんでした</Alert>}
        <Text color="pink" fontWeight="bold" block>
          完了すると、患者は服薬指導を受けられなくなります。
        </Text>
        <Text block>既に服薬指導を終えている場合のみ、完了を押してください。</Text>
        <Box
          mt={theme.space.l}
          backgroundColor={theme.colors.background.bg}
          padding={`${theme.space.l} ${theme.space.l} ${theme.space.s}`}
        >
          <SimplePatientProfile patientId={props.patientId} />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={loading} onClick={handleFinish}>
          完了
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
