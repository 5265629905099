import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Flex, Text } from '~/components/blocks';

export const DeviceError = () => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" justifyContent="center">
      <Alert status="error" m={theme.space.xxl}>
        <Text block textAlign="left" size="l" fontWeight="bold">
          カメラの設定中にエラーが発生しました
        </Text>
        <Text block textAlign="left" mt={theme.space.m}>
          カメラが接続されていてアクセスが許可されているかご確認ください
        </Text>
      </Alert>
    </Flex>
  );
};
