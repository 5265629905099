import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';

import { Box, Button, Flex, Icon, Name, Tooltip } from '~/components/blocks';
import { LinkPatientButton } from '~/components/partials';
import { CheckinEntryDetailFragment } from '~/graphql';

import { ContactList } from './ContactList';
import { ProfileDetail } from './ProfileDetail';
import { ProfileSkeleton } from './ProfileSkeleton';

type Props = {
  loading: boolean;
  checkinEntry?: CheckinEntryDetailFragment | null;
};

const Contact = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    padding: `${theme.space.m} ${theme.space.l}`,
    marginBottom: theme.space.l,
  }),
);

export const CheckinPatientProfile = React.memo((props: Props) => {
  const { loading, checkinEntry } = props;

  const [isExpanded, setExpanded] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({ duration: 100, isExpanded });

  return (
    <Box>
      {loading || !checkinEntry ? (
        <ProfileSkeleton />
      ) : (
        <>
          <Flex alignItems="center">
            <Name
              size="l"
              familyName={checkinEntry.familyName}
              givenName={checkinEntry.givenName}
              phoneticFamilyName={checkinEntry.phoneticFamilyName}
              phoneticGivenName={checkinEntry.phoneticGivenName}
            />
          </Flex>
          <Box>
            <ProfileDetail>
              <ProfileDetail.Item>-</ProfileDetail.Item>
              <ProfileDetail.Item>
                <Flex>
                  <Tooltip
                    content={
                      <>
                        連携できるのは、CLINICS
                        <br />
                        アプリ経由の予約のみです
                      </>
                    }
                  >
                    <div>
                      <LinkPatientButton disabled />
                    </div>
                  </Tooltip>
                  <Button
                    size="ms"
                    {...getToggleProps({
                      onClick: () => setExpanded((_prev) => !_prev),
                    })}
                  >
                    <Icon size="s" icon="people" />
                    {isExpanded ? '閉じる' : '詳細'}
                  </Button>
                </Flex>
              </ProfileDetail.Item>
            </ProfileDetail>
          </Box>
          <div {...getCollapseProps()}>
            <Contact>
              <ContactList>
                <ContactList.Item>
                  <Icon size="m" icon="tel" />
                  {checkinEntry.phoneNumber}
                </ContactList.Item>
              </ContactList>
            </Contact>
          </div>
        </>
      )}
    </Box>
  );
});

CheckinPatientProfile.displayName = 'CheckinPatientProfile';
