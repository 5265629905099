export type Fields = {
  quantity: number;
  pieces: Array<number | ''>;
};

export const SelectQuantities = [1, 2, 3, 4, 5];

// 本来ならOpenAPIから出力したいが使わないものが多いので手動で定義
export const UberDeliveryErrorCode = {
  addressUndeliverable: 'address_undeliverable',
  // TODO: 不要であることが確認できたら削除する
  couriersBusy: 'couriers_busy',
  addressUndeliverableLimitedCouriers: 'address_undeliverable_limited_couriers',
} as const;
