import React from 'react';
import { useRecoilCallback } from 'recoil';

import { useSwapDrawer } from '~/hooks/use-swap-drawer';
import { draftAppointmentPatientProfileDrawerState } from '~/state/reception/atoms';

import { FinishDraftAppointmentModal } from './FinishDraftAppointmentModal';
import { ProfileDrawer } from './ProfileDrawer';
import { RequestAppointmentModal } from './RequestAppointmentModal';

export const DraftAppointmentPatientProfileDrawer = () => {
  const {
    state: { isOpen, draftAppointmentId, patientId },
    handleTransitionEnd,
  } = useSwapDrawer(draftAppointmentPatientProfileDrawerState, 'draftAppointmentId');

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(draftAppointmentPatientProfileDrawerState);
      },
    [],
  );

  if (!draftAppointmentId) {
    return null;
  }

  return (
    <>
      <ProfileDrawer
        open={isOpen}
        draftAppointmentId={draftAppointmentId}
        patientId={patientId}
        onClose={handleClose}
        onTransitionEnd={handleTransitionEnd}
      />
      <RequestAppointmentModal draftAppointmentId={draftAppointmentId} />
      <FinishDraftAppointmentModal draftAppointmentId={draftAppointmentId} patientId={patientId} />
    </>
  );
};
