import { useTheme } from '@emotion/react';
import React, { useCallback, useState } from 'react';

import { Box, Button, Checkbox, Flex, Modal, Text } from '~/components/blocks';

type Props = {
  onNext: () => void;
};

export const ConfirmFaxPanel = (props: Props) => {
  const theme = useTheme();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const handleClick = useCallback(() => setIsConfirmed((_state) => !_state), []);

  return (
    <>
      <Modal.Body>
        <Flex alignItems="flex-start" flexDirection="column">
          <Text>この患者の処方箋をFAX等で受理しましたか？</Text>
          <Box marginTop={theme.space.m}>
            <Checkbox label="はい" checked={isConfirmed} onChange={handleClick} />
          </Box>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" disabled={!isConfirmed} onClick={props.onNext}>
          次へ
        </Button>
      </Modal.Footer>
    </>
  );
};
