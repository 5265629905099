import React from 'react';

import { Drawer } from '~/components/blocks';
import { Appointment, WebVisitorAppointmentDetailFragment } from '~/graphql';

import { BookedButtons } from './BookedButtons';
import { ChargedButtons } from './ChargedButtons';
import { PendingButtons } from './PendingButtons';
import { WaitingForChargeButtons } from './WaitingForChargeButtons';

type Props = {
  appointment: WebVisitorAppointmentDetailFragment;
};

export const Footer = React.memo((props: Props) => {
  const { appointment } = props;
  const appointmentStatus = appointment?.status;
  const isVisible = (
    ['pending', 'booked', 'waiting_for_charge', 'charged', 'finished'] as Array<
      Appointment['status'] | undefined
    >
  ).includes(appointmentStatus);

  if (!appointment || !isVisible) return null;

  if (appointmentStatus === 'finished') return null;

  return (
    <Drawer.Footer>
      {appointmentStatus === 'pending' ? (
        <PendingButtons appointment={appointment} />
      ) : appointmentStatus === 'booked' ? (
        <BookedButtons appointment={appointment} />
      ) : appointmentStatus === 'waiting_for_charge' ? (
        <WaitingForChargeButtons appointment={appointment} />
      ) : appointmentStatus === 'charged' ? (
        <ChargedButtons appointment={appointment} />
      ) : null}
    </Drawer.Footer>
  );
});

Footer.displayName = 'Footer';
