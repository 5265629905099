import { BrowserQRCodeReader, DecodeHintType } from '@zxing/library';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilCallback } from 'recoil';

import {
  newReceptionDrawerState,
  prescriptionQrCodeReaderDialogState,
} from '~/state/reception/atoms';
import { QrCodePatient } from '~/state/reception/types';

import { useParseReadText } from './use-parse-read_text';

export const useReadQrCode = () => {
  const { error: parsingError, findPatient, resetError: resetParsingError } = useParseReadText();

  const videoRef = useRef<HTMLVideoElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [hasDeviceError, setHasDeviceError] = useState(false);
  const [readingError, setReadingError] = useState<string | null>(null);

  // FIXME: UXを考慮してdeprecatedの本ライブラリを利用する
  // クラスが削除された場合、以下の対応を行う
  // - 本ライブラリのdecodeメソッドを使って書き換える
  // - @zxing/browserへ乗り換える
  //     - @xzing/browserの認識率は本ライブラリより低いことがわかっているため、検証を行うこと
  const codeReader = useMemo(() => {
    const reader = new BrowserQRCodeReader();
    const hints = new Map();
    hints.set(DecodeHintType.ASSUME_CODE_39_CHECK_DIGIT, false);
    reader.hints = hints;
    reader.timeBetweenDecodingAttempts = 100;

    return reader;
  }, []);

  const searchUserMedia = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (stream.getVideoTracks().length === 0) {
          setHasDeviceError(true);
        }
      })
      .catch(() => {
        setHasDeviceError(true);
      })
      .finally(() => {
        setIsInitializing(false);
      });
  }, []);
  const resetError = useCallback(() => {
    if (parsingError) {
      resetParsingError();
    }

    if (readingError) {
      setReadingError(null);
    }
  }, [parsingError, readingError, resetParsingError]);
  const resetReader = useCallback(() => {
    codeReader.stopAsyncDecode();
    codeReader.reset();
  }, [codeReader]);
  const finish = useRecoilCallback(
    ({ set, reset }) =>
      (qrCodePatient: QrCodePatient) => {
        setIsLoading(true);

        // 読み取りが完了してる感を演出する
        setTimeout(() => {
          resetReader();
          set(newReceptionDrawerState, (_state) => ({ ..._state, qrCodePatient }));
          reset(prescriptionQrCodeReaderDialogState);

          return () => {
            setIsLoading(false);
          };
        }, 1500);
      },
    [resetReader],
  );
  const startScan = useCallback(() => {
    codeReader.decodeFromVideoDevice(
      null, // deviceId = nullで外向きのカメラ指定
      videoRef.current,
      (result) => {
        if (result) {
          const _patient = findPatient(result);
          if (_patient) {
            finish(_patient);
          } else {
            setReadingError(
              '患者情報の読み取りができませんでした。\n別のQRコードを読み込んでください。',
            );
          }
        }
      },
    );
  }, [codeReader, findPatient, finish]);

  useEffect(() => {
    if (isInitializing) return;
    if (hasDeviceError) return;
    if (!videoRef.current) return;

    startScan();

    return () => {
      resetReader();
    };
  }, [codeReader, findPatient, finish, resetReader, hasDeviceError, isInitializing, startScan]);

  useEffect(() => {
    searchUserMedia();
  }, [searchUserMedia]);

  return {
    videoRef,
    loading: isLoading || isInitializing,
    hasDeviceError,
    error: parsingError || readingError,
    resetError,
  };
};
