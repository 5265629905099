import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { useGetBookmarkPatientListLazyQuery } from '~/graphql';
import { getMe } from '~/graphql/utility';
import { bookmarkPatientListModalState } from '~/state/reception/atoms';

export const useBookmarkPatientList = () => {
  const { isOpen, yearMonth } = useRecoilValue(bookmarkPatientListModalState);
  const [pageInfo, setPageInfo] = useRecoilState(bookmarkPatientListModalState);
  const [getBookmarkPatientList, { data, loading }] = useGetBookmarkPatientListLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const bookmarkedPatients = getMe(_data)?.organization?.bookmarkedPatients;
      const bookmarkedPfPatientsCount = getMe(_data)?.organization?.bookmarkedPfPatientsCount || 0;
      if (bookmarkedPatients) {
        const totalPage = bookmarkedPatients.pagesCount ?? 1;

        setPageInfo((state) => ({
          ...state,
          totalPage: totalPage,
          patientCount: bookmarkedPfPatientsCount,
        }));
      }
    },
  });

  // 連続して検索条件が変化した時に都度リクエストしないようにする
  const getBookmarkPatientListDebounce = useDebouncedCallback(async () => {
    await getBookmarkPatientList({
      variables: {
        month: yearMonth ? yearMonth.getMonth() + 1 : null,
        page: pageInfo.page,
        perPage: pageInfo.perPage,
        year: yearMonth ? yearMonth.getFullYear() : null,
        statuses: ['status1', 'status2', 'status3', 'status4', 'status5'], // "status6" "キャンセル"
        draftAppointmentStatuses: ['available', 'wait_for_booking', 'finished'],
      },
    });
  }, 300);

  const bookmarkPatientList = useMemo(
    () => (data ? getMe(data)?.organization.bookmarkedPatients.nodes || [] : []),
    [data],
  );

  const month = yearMonth ? yearMonth.getMonth() + 1 : null;
  const year = yearMonth ? yearMonth.getFullYear() : null;

  useEffect(() => {
    if (isOpen) {
      getBookmarkPatientListDebounce();
    }
  }, [isOpen, month, year, pageInfo.page, pageInfo.perPage, getBookmarkPatientListDebounce]);

  return { loading, bookmarkPatientList };
};
