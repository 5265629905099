import { useTheme } from '@emotion/react';
import React, { useCallback } from 'react';

import { Flex, Icon, Name, Radio, Text } from '~/components/blocks';
import { DirectVisitorItemFragment } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  disabled: boolean;
  radioName?: string;
  radioValue: string | null;
  candidate: DirectVisitorItemFragment;
  onClick: (directVisitorId: string) => void;
};

export const DirectVisitorCandidate = React.memo((props: Props) => {
  const { disabled, radioName, candidate, radioValue, onClick } = props;
  const theme = useTheme();

  const handleClick = useCallback(() => onClick(candidate.id), [candidate.id, onClick]);

  return (
    <Flex alignItems="center" paddingX={theme.space.m} onClick={disabled ? undefined : handleClick}>
      <Radio
        disabled={disabled}
        name={radioName ?? 'candidate'}
        value={candidate.id}
        checked={radioValue === candidate.id}
        onChange={handleClick}
      />
      <Flex marginLeft={theme.space.m} alignItems="center">
        <Icon icon="patient" size="xxxl" color="black" />
        <Flex marginLeft={theme.space.m} flexDirection="column">
          <Name
            size="s"
            familyName={candidate.familyName}
            givenName={candidate.givenName}
            phoneticFamilyName={candidate.phoneticFamilyName}
            phoneticGivenName={candidate.phoneticGivenName}
          />
          <Text size="xs" marginTop={theme.space.s}>
            {Label.warekiBirthDate(candidate.birthDate)}
          </Text>
          <Text size="xs">{candidate.mobilePhone}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
});

DirectVisitorCandidate.displayName = 'DirectVisitorCandidate';
