import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetCheckinEntryDetailLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { checkinNotifyConfirmModalState } from '~/state/reception/atoms';

export const useGetCheckinEntryProfile = () => {
  const { isOpen, checkinEntryId } = useRecoilValue(checkinNotifyConfirmModalState);
  const [getCheckinEntryProfile, { data, error, loading }] = useGetCheckinEntryDetailLazyQuery();
  const checkinEntry = getNode(data, 'CheckinEntry');

  useEffect(() => {
    if (isOpen && checkinEntryId) {
      getCheckinEntryProfile({ variables: { id: checkinEntryId } });
    }
  }, [isOpen, checkinEntryId, getCheckinEntryProfile]);

  return { checkinEntry, error, loading };
};
