import { useCallback, useEffect, useState } from 'react';
import { RecoilState, RecoilValueReadOnly, useRecoilValue } from 'recoil';

type BaseState = {
  isOpen: boolean;
};

export const useSwapDrawer = <State extends BaseState>(
  atom: RecoilState<State> | RecoilValueReadOnly<State>,
  monitoringPropertyName: keyof State | Array<keyof State>,
) => {
  const state = useRecoilValue(atom);
  const monitoringProperty = Array.isArray(monitoringPropertyName)
    ? monitoringPropertyName.map((name) => state[name])
    : [state[monitoringPropertyName]];
  const [currentProps, setCurrentProps] = useState<State>(state);

  const handleTransitionEnd = useCallback(() => {
    setCurrentProps(state);
  }, [state]);

  useEffect(() => {
    setCurrentProps((_state) => ({
      ..._state,
      isOpen: false,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, monitoringProperty);

  useEffect(() => {
    if (state.isOpen) {
      setCurrentProps(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isOpen]);

  return {
    state: currentProps,
    handleTransitionEnd,
  };
};
