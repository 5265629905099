import { useRecoilCallback, useRecoilValue } from 'recoil';

import { useGenerateDeliveryCsvMutation } from '~/graphql';
import { b2CloudDownloadModalState } from '~/state/reception/atoms';

export const useGenerateCsv = () => {
  const { appointmentIds } = useRecoilValue(b2CloudDownloadModalState);

  const [generateCsv] = useGenerateDeliveryCsvMutation();

  const setError = useRecoilCallback(
    ({ set }) =>
      (message: string | null) => {
        set(b2CloudDownloadModalState, (_state) => ({ ..._state, error: message }));
      },
    [],
  );
  const generate = useRecoilCallback(
    ({ set, reset }) =>
      async (willFinished: boolean) => {
        if (appointmentIds.length === 0) {
          setError('ダウンロードする患者を選択してください');
          return;
        } else {
          setError(null);
        }

        try {
          set(b2CloudDownloadModalState, (_state) => ({ ..._state, disabled: true }));

          const result = await generateCsv({
            variables: {
              input: {
                appointmentIds,
                willFinished,
              },
            },
          });
          const url = result.data?.generateDeliveryCsv?.url;
          if (!url) {
            setError('エラーが発生しました。再度実行してください');
            return;
          }

          const link = document.createElement('a');
          link.href = url;
          link.click();

          reset(b2CloudDownloadModalState);
        } catch (_error) {
          setError(_error?.message || 'エラーが発生しました');
        } finally {
          set(b2CloudDownloadModalState, (_state) => ({ ..._state, disabled: false }));
        }
      },
    [appointmentIds, generateCsv, setError],
  );

  return generate;
};
