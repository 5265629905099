import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Grid, Header } from '~/components/blocks';
import { CheckinEntryNewReceptionPanel } from '~/components/pages/Reception/CheckinEntryNewReceptionPanel';
import { newReceptionDrawerState } from '~/state/reception/atoms';
import { NewReceptionPanelMap } from '~/state/reception/types';

export const InputCheckinEntryVisitorPanel = () => {
  const { selectedCandidate } = useRecoilValue(newReceptionDrawerState);

  const handleBackClick = useRecoilCallback(
    ({ set }) =>
      () =>
        set(newReceptionDrawerState, (_state) => ({
          ..._state,
          currentPanel: NewReceptionPanelMap.select,
        })),
    [],
  );

  if (selectedCandidate?.__typename !== 'CheckinEntry') {
    return null;
  }

  return (
    <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
      <Header attached="drawer">新患受付</Header>
      <CheckinEntryNewReceptionPanel
        checkinEntry={selectedCandidate}
        onBackClick={handleBackClick}
      />
    </Grid>
  );
};
