import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useRef } from 'react';

import { Alert, Box, Flex, Grid, Hint, ScrollBox, Table } from '~/components/blocks';

import { BookmarkPatient } from './BookmarkPatient';
import { BookmarkPatientPagination } from './BookmarkPatientPagination';
import { useBookmarkPatientList } from './use-fetch-bookmark_patient_list';

const TABLE_HEIGHT = '420px';

const TableWrapper = styled(Box)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    minHeight: TABLE_HEIGHT,
    padding: `${theme.space.m} ${theme.space.l}`,
  }),
);

export const BookmarkPatientList = React.memo(() => {
  const theme = useTheme();
  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const { loading, bookmarkPatientList } = useBookmarkPatientList();
  const hintText = (
    <>
      キャンセルを除いた
      <br />
      総申し込み数
    </>
  );

  return (
    <Grid
      height="100%"
      gridTemplateRows="min-content 1fr"
      gridTemplateColumns="1fr"
      overflow="auto"
    >
      <ScrollBox ref={scrollRef} loading={loading} height={TABLE_HEIGHT}>
        <TableWrapper>
          <Table appearance="noline">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>登録日</Table.Th>
                <Table.Th>患者名</Table.Th>
                <Table.Th textAlign="right">
                  <Flex justifyContent="flex-end">
                    申し込み数
                    <Hint placement="top">{hintText}</Hint>
                  </Flex>
                </Table.Th>
                <Table.Th>最終申し込み日</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {bookmarkPatientList.map((patient) => (
                <BookmarkPatient key={patient.id} patient={patient} />
              ))}
            </Table.Tbody>
          </Table>
          {!loading && bookmarkPatientList.length === 0 && (
            <Box height="360px">
              <Alert status="info">条件に一致する患者は見つかりませんでした</Alert>
            </Box>
          )}
        </TableWrapper>
      </ScrollBox>
      <Box paddingY={theme.space.l}>
        {bookmarkPatientList.length > 0 && <BookmarkPatientPagination />}
      </Box>
    </Grid>
  );
});

BookmarkPatientList.displayName = 'BookmarkPatientList';
