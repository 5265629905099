import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { Flex, ToolBar } from '~/components/blocks';
import { InvitationButton } from '~/components/pages/Reception/Header/InvitationButton';
import { SelectDateWithMoveButton } from '~/components/partials/SelectDateWithMoveButton';
import {
  GetWebBookingsSettingQuery,
  OrganizationAppointmentStatus,
  useGetWebBookingsSettingQuery,
} from '~/graphql';
import { getMe } from '~/graphql/utility';
import { receptionPageInfoState } from '~/state/reception/atoms';

import { StatusFilter } from '../StatusFilter';
import { BookmarkButton } from './BookmarkButton';
import { FilterIncompleteButton } from './FilterIncompleteButton';
import { IncompleteCount } from './IncompleteCount';
import { Menu } from './Menu';
import { SearchBox } from './SearchBox';
import { useFetchIncompleteTaskCount } from './use-fetch-incomplete_task_count';

const DaySort = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    borderRight: theme.borders.default,
    paddingRight: theme.space.l,
  }),
);

const StatusBox = styled('div')(({ theme }) =>
  css({
    borderLeft: theme.borders.default,
    marginLeft: theme.space.m,
  }),
);

const IncompleteBox = styled(Flex)(({ theme }) =>
  css({
    borderLeft: theme.borders.default,
    paddingLeft: theme.space.l,
    whiteSpace: 'nowrap',
    alginItems: 'center',
  }),
);

const validateInvitation = (data: GetWebBookingsSettingQuery) => {
  if (!data) return false;

  const setting = getMe(data)?.organization.webBookingsSetting;
  return setting ? setting.published && setting.telemedicine : false;
};

export const Header = () => {
  const { data } = useGetWebBookingsSettingQuery();
  const { loading, totalCount, handleShow } = useFetchIncompleteTaskCount();
  const enabledInvitation = useMemo(() => (data ? validateInvitation(data) : false), [data]);

  const [pageInfo, setPageInfo] = useRecoilState(receptionPageInfoState);
  const handleChangeDate = useCallback(
    (date: Date | null) => setPageInfo((state) => ({ ...state, date, page: 1 })),
    [setPageInfo],
  );

  const handleChangeStatuses = useCallback(
    (statuses: OrganizationAppointmentStatus[]) =>
      setPageInfo((state) => ({ ...state, statuses, page: 1 })),
    [setPageInfo],
  );

  return (
    <ToolBar appearance="sort">
      <ToolBar.Item>
        <DaySort>
          <SelectDateWithMoveButton value={pageInfo.date} onChange={handleChangeDate} clearable />
        </DaySort>
      </ToolBar.Item>
      <ToolBar.Item>
        <SearchBox />
      </ToolBar.Item>
      {!loading && (
        <>
          <StatusBox>
            <StatusFilter value={pageInfo.statuses} onChange={handleChangeStatuses} />
          </StatusBox>
          <IncompleteBox>
            <IncompleteCount totalCount={totalCount} />
            <FilterIncompleteButton totalCount={totalCount} onClick={handleShow} />
          </IncompleteBox>
        </>
      )}
      <ToolBar.Item position="right">
        <Flex>
          {enabledInvitation && <InvitationButton />}
          <BookmarkButton />
          <Menu />
        </Flex>
      </ToolBar.Item>
    </ToolBar>
  );
};
