import { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { PlanFeatureCode, Sex, useCreateDirectVisitorMutation } from '~/graphql';
import { usePlanFeature } from '~/hooks/use-plan-feature';
import { followupMessageSettingModalState } from '~/state/partials/followup_message_setting_modal/atoms';
import { newReceptionDrawerState } from '~/state/reception/atoms';

import { Fields } from '../GuestPatientForm/types';

export const useCreateDirectVisitor = () => {
  const { hasEnabledFeature: hasEnabledFollowup } = usePlanFeature(PlanFeatureCode.Followup);

  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [createDirectVisitor] = useCreateDirectVisitorMutation();

  const create = useRecoilCallback(
    ({ set, reset }) =>
      async (values: Fields) => {
        setIsCreating(true);
        setError(null);

        const sex =
          values.sex === 'male' ? Sex.Male : values.sex === 'female' ? Sex.Female : Sex.Unknown;

        try {
          const result = await createDirectVisitor({
            variables: {
              input: {
                ...values,
                sex,
              },
            },
          });

          const directVisitorId = result.data?.createDirectVisitor?.directVisitor.id;
          if (hasEnabledFollowup && directVisitorId) {
            set(followupMessageSettingModalState, (_state) => ({
              ..._state,
              isOpen: true,
              guestPatientId: directVisitorId,
            }));
          }
          reset(newReceptionDrawerState);
        } catch (_error) {
          const _message = _error?.message || 'エラーが発生しました';
          setError(_message);
          throw new Error(_message);
        } finally {
          setIsCreating(false);
        }
      },
    [createDirectVisitor, hasEnabledFollowup],
  );

  return {
    creating: isCreating,
    error,
    create,
  };
};
