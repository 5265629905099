import { useCallback, useEffect, useState } from 'react';

import { useUpdateCheckinEntryDescriptionMutation } from '~/graphql';

export const useUpdateMemo = (checkinEntryId: string) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [latestMemo, setLatestMemo] = useState({ memo: '', mutationCalled: false });

  const [updateMemo] = useUpdateCheckinEntryDescriptionMutation({
    onCompleted: (_data) => {
      // Apollo or useMemo のキャッシュまわりの不整合が発生するので、メモ更新後は Mutation の返り値を使う
      // FYI https://github.com/medley-inc/pharms/issues/1852
      const latestDescription = _data.updateCheckinDescription?.checkinEntry.description || '';

      // updateDescriptionData はリロード走るまで値を保持し続けるので、別の受付ドロワーを開いたタイミングでリセットできるようにステート管理する
      setLatestMemo({ memo: latestDescription, mutationCalled: true });
    },
  });

  const update = useCallback(
    async (visitId: string, memo: string) => {
      setIsUpdating(true);
      setError(null);

      try {
        await updateMemo({
          variables: {
            input: {
              checkinEntryId,
              description: memo,
            },
          },
        });
      } catch (_error) {
        setError(_error?.message ?? 'エラーが発生しました');
      }
      setIsUpdating(false);
    },
    [checkinEntryId, updateMemo],
  );

  useEffect(() => {
    // visitが切り替わった時にリセットする
    setLatestMemo({ memo: '', mutationCalled: false });
  }, [checkinEntryId]);

  return {
    updating: isUpdating,
    error,
    latestMemo,
    update,
  };
};
