import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Checkbox, Flex, Grid, ScrollBox, Table, Text } from '~/components/blocks';
import { AppointmentForDeliveryFragment } from '~/graphql';
import { b2CloudDownloadModalState } from '~/state/reception/atoms';

import { Appointment } from './Appointment';

const HEIGHT = '420px';

const TableWrapper = styled(Box)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    minHeight: HEIGHT,
    padding: `${theme.space.m} ${theme.space.l}`,
  }),
);

type Props = {
  notFound: boolean;
  loading: boolean;
  appointments: AppointmentForDeliveryFragment[];
};

export const AppointmentList = React.memo((props: Props) => {
  const theme = useTheme();

  const { loading, notFound, appointments } = props;

  const scrollRef = useRef<RefAttributeType<typeof ScrollBox> | null>(null);
  const [state, setState] = useRecoilState(b2CloudDownloadModalState);
  const [allChecked, setAllChecked] = useState(true);

  const appointmentIds = useMemo(() => appointments.map(({ id }) => id), [appointments]);
  const totalCount = appointments.length;

  const handleClickAllChecked = useCallback(() => {
    // allChecked
    // - チェック済み(true) -> 未チェック(false)
    // - 未チェック(false) -> チェック済み(true)
    setState((_state) => ({
      ..._state,
      error: null,
      appointmentIds: allChecked ? [] : appointmentIds,
    }));
    setAllChecked(!allChecked);
  }, [allChecked, appointmentIds, setState]);
  const handleClick = useCallback(
    (id: string) => {
      const index = state.appointmentIds.indexOf(id);
      const newIds: string[] = [...state.appointmentIds];
      if (index > -1) {
        newIds.splice(index, 1);
      } else {
        newIds.push(id);
      }
      setState((_state) => ({ ..._state, error: null, appointmentIds: newIds }));

      if (newIds.length === totalCount) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    },
    [setState, state.appointmentIds, totalCount],
  );

  useEffect(() => {
    if (state.isOpen) {
      setState((_state) => ({
        ..._state,
        error: null,
        appointmentIds,
      }));
      setAllChecked(true);
    }
  }, [appointmentIds, setState, state.isOpen]);

  return (
    <Grid
      height="100%"
      gridTemplateRows="min-content 1fr"
      gridTemplateColumns="1fr"
      overflow="auto"
    >
      <Box marginY={theme.space.m}>
        配達待ち（受取方法：通常配達）の患者<Text marginX={theme.space.s}>{totalCount}</Text>件中
        <Text fontWeight="bold" marginX={theme.space.s}>
          {state.appointmentIds.length}件
        </Text>
        のCSVをダウンロードします。
      </Box>
      <ScrollBox ref={scrollRef} loading={loading} height={HEIGHT}>
        <TableWrapper>
          <Table appearance="noline">
            <Table.Thead>
              <Table.Tr>
                <Table.Th textAlign="center">
                  <Flex alignItems="center">
                    <Checkbox
                      checked={allChecked}
                      disabled={state.disabled || notFound}
                      onChange={handleClickAllChecked}
                    />
                  </Flex>
                </Table.Th>
                <Table.Th>申し込み日</Table.Th>
                <Table.Th>患者名</Table.Th>
                <Table.Th></Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {appointments.map((appointment) => (
                <Appointment
                  key={appointment.id}
                  appointment={appointment}
                  checked={state.appointmentIds.indexOf(appointment.id) > -1}
                  onClick={handleClick}
                />
              ))}
            </Table.Tbody>
          </Table>
          {notFound && (
            <Box height="360px">
              <Alert status="info">配達待ちの患者は見つかりませんでした</Alert>
            </Box>
          )}
        </TableWrapper>
      </ScrollBox>
    </Grid>
  );
});

AppointmentList.displayName = 'AppointmentList';
