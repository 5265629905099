import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetCheckinEntryForFinishConfirmModalLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { checkinFinishConfirmModalModalState } from '~/state/reception/atoms';

export const useGetCheckinEntryProfile = () => {
  const { isOpen, checkinEntryId } = useRecoilValue(checkinFinishConfirmModalModalState);
  const [getCheckinEntryProfile, { data }] = useGetCheckinEntryForFinishConfirmModalLazyQuery();
  const checkinEntry = getNode(data, 'CheckinEntry');

  useEffect(() => {
    if (isOpen && checkinEntryId) {
      getCheckinEntryProfile({ variables: { id: checkinEntryId } });
    }
  }, [isOpen, checkinEntryId, getCheckinEntryProfile]);

  return { checkinEntry };
};
