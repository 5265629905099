import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Button, Loader, Modal, Name, Table, Text } from '~/components/blocks';
import { checkinFinishConfirmModalModalState } from '~/state/reception/atoms';
import { Label } from '~/utils/label';

import { useFinishCheckinEntry } from '../use-finish-checkin_entry';
import { useGetCheckinEntryProfile } from './use-get_checkin_entry_profile';

const PatientInformation = styled('div')(({ theme }) =>
  css({
    width: '100%',
    backgroundColor: theme.colors.background.bg,
    padding: theme.space.m,
    marginTop: theme.space.l,
  }),
);

export const FinishConfirmModal = () => {
  const theme = useTheme();
  const { isOpen, checkinEntryId } = useRecoilValue(checkinFinishConfirmModalModalState);
  const { finish, finishing, error } = useFinishCheckinEntry(checkinEntryId || '');
  const { checkinEntry } = useGetCheckinEntryProfile();

  const close = useRecoilCallback(
    ({ reset }) =>
      () =>
        reset(checkinFinishConfirmModalModalState),
    [],
  );

  const handleFinished = useCallback(async () => {
    try {
      await finish();
      close();
    } catch (e) {
      // 何もしない
    }
  }, [close, finish]);

  return (
    <Modal open={isOpen} size="s" onClose={close}>
      <Modal.Header>チェックイン対応完了確認</Modal.Header>
      <Modal.Body>
        {error && (
          <Alert status="error" mb={theme.space.l}>
            {error}
          </Alert>
        )}
        {checkinEntry ? (
          <>
            患者へ通知を行う前に完了すると通知できなくなります。
            <br />
            本当に完了しますか？
            <PatientInformation>
              <Table appearance="noline" whiteSpace="nowrap">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>チェックイン日時</Table.Th>
                    <Table.Th>患者名</Table.Th>
                    <Table.Th>電話番号</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>
                      <Text block size="s" fontWeight="bold" whiteSpace="nowrap">
                        {Label.HHMM(checkinEntry.enteredAt)}
                      </Text>
                      <Text block size="xs" whiteSpace="nowrap">
                        {Label.YYYYMMDDja(checkinEntry.enteredAt)}
                      </Text>
                    </Table.Td>
                    <Table.Td>
                      <Name {...checkinEntry} size="s" />
                    </Table.Td>
                    <Table.Td>{checkinEntry.phoneNumber}</Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </PatientInformation>
          </>
        ) : (
          <Loader open inside />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" loading={finishing} onClick={handleFinished}>
          完了
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
