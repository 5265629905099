import React from 'react';

import { Table } from '~/components/blocks';
import { InvitationCodeItemFragment } from '~/graphql';

import { InvitationHistoryItem } from './InvitationHistoryItem';

type Props = {
  invitationHistories: InvitationCodeItemFragment[];
};

export const InvitationHistoryList = React.memo((props: Props) => {
  return (
    <Table appearance="noline" whiteSpace="nowrap">
      <Table.Thead>
        <Table.Tr>
          <Table.Th>送信日時</Table.Th>
          <Table.Th>申込コード</Table.Th>
          <Table.Th>電話番号</Table.Th>
          <Table.Th>メモ</Table.Th>
          <Table.Th>対応スタッフ</Table.Th>
          <Table.Th>申し込み状況</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {props.invitationHistories.map((invitationHistory) => (
          <InvitationHistoryItem key={invitationHistory.id} invitationHistory={invitationHistory} />
        ))}
      </Table.Tbody>
    </Table>
  );
});

InvitationHistoryList.displayName = 'InvitationHistoryList';
