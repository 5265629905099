import { object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';
import { mobilePhoneRegexp } from '~/constants/regexp';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  phoneNumber: string()
    .label('電話番号')
    .matches(mobilePhoneRegexp, FormErrorMessage.invalid)
    .required(FormErrorMessage.required),
  memo: string().label('メモ').trim().max(30, FormErrorMessage.maxLengthRequired),
});
