import { useTheme } from '@emotion/react';
import React, { useCallback, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Box, Modal, Tabs, Text } from '~/components/blocks';
import { InvitationHistoryPanel } from '~/components/pages/Reception/InvitationModal/InvitationHistoryPanel';
import { SendInvitationPanel } from '~/components/pages/Reception/InvitationModal/SendInvitationPanel';
import { invitationModalState } from '~/state/reception/atoms';

export const InvitationModal = () => {
  const theme = useTheme();
  const { isOpen } = useRecoilValue(invitationModalState);
  const resetState = useResetRecoilState(invitationModalState);
  const [tab, setTab] = useState('SendInvitation');

  const handleClose = useCallback(() => {
    resetState();
    setTab('SendInvitation');
  }, [resetState]);

  const handleChange = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const element = e.target as HTMLButtonElement;
      const newTab = element.dataset['tab'] as string;

      setTab(newTab);
    },
    [setTab],
  );

  return (
    <Modal open={isOpen} size="l" onClose={handleClose}>
      <Modal.Header>
        <Text marginRight={theme.space.xl} size="l">
          申込コード送信
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Tabs>
          <Tabs.Tab
            active={tab === 'SendInvitation'}
            data-tab={'SendInvitation'}
            onClick={handleChange}
          >
            送信
          </Tabs.Tab>
          <Tabs.Tab
            active={tab === 'InvitationHistory'}
            data-tab={'InvitationHistory'}
            onClick={handleChange}
          >
            履歴
          </Tabs.Tab>
        </Tabs>
        <Box my={theme.space.xl} height="420px">
          {tab === 'SendInvitation' && <SendInvitationPanel />}
          {tab === 'InvitationHistory' && <InvitationHistoryPanel />}
        </Box>
      </Modal.Body>
    </Modal>
  );
};
