import { useRecoilValue } from 'recoil';

import { Fields } from '~/components/pages/Reception/InvitationModal/SendInvitationPanel/types';
import { invitationModalState } from '~/state/reception/atoms';

export const useDefaultValues = () => {
  const { phoneNumber, memo } = useRecoilValue(invitationModalState);

  const defaultValues: Fields = {
    phoneNumber: phoneNumber,
    memo: memo,
  };

  return defaultValues;
};
