import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Button, Flex, Icon } from '~/components/blocks';
import { SelectMonth } from '~/components/partials';
import { toYYYYMMja } from '~/utils/date';

type Props = {
  value: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  onChange: (date: Date | null) => void;
};

const MoveButton = styled(Button)(({ theme }) =>
  css({
    padding: `${theme.space.s} ${theme.space.m}`,
    '&:disabled': {
      backgroundColor: theme.colors.colorPallete.grey03,
    },
  }),
);

export const SelectMonthWithMoveButton = React.memo((props: Props) => {
  const { value, onChange, maxDate, minDate } = props;
  const theme = useTheme();
  const disablePrev = !value || (!!minDate && toYYYYMMja(value) == toYYYYMMja(minDate));
  const disableNext = !value || (!!maxDate && toYYYYMMja(value) == toYYYYMMja(maxDate));
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });

  const handleClickPrev = useCallback(() => {
    if (!value) return;

    const prevDate = new Date(value.getTime());
    prevDate.setMonth(value.getMonth() - 1);
    props.onChange(prevDate);
  }, [props, value]);

  const handleClickNext = useCallback(() => {
    if (!value) return;

    const nextDate = new Date(value.getTime());
    nextDate.setMonth(value.getMonth() + 1);
    props.onChange(nextDate);
  }, [props, value]);

  return (
    <Flex height={isTablet ? '34px' : undefined}>
      <MoveButton icon marginRight={theme.space.s} onClick={handleClickPrev} disabled={disablePrev}>
        <Icon icon="arrow-left" size="l" />
      </MoveButton>
      <SelectMonth
        clearable
        value={value}
        onChange={onChange}
        maxDate={maxDate}
        minDate={minDate}
      />
      <MoveButton icon marginLeft={theme.space.s} onClick={handleClickNext} disabled={disableNext}>
        <Icon icon="arrow-right" size="l" />
      </MoveButton>
    </Flex>
  );
});

SelectMonthWithMoveButton.displayName = 'SelectMonthWithMoveButton';
