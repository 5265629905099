import React, { useMemo } from 'react';

import { Tag, TagGroup } from '~/components/blocks';
import { DeliveryTag, isDeliveryTag, isMedicatoinFollowupTag } from '~/components/partials';

type Props = {
  description: string;
};

export const ReadOnlyMemo = React.memo((props: Props) => {
  const { description } = props;

  const memos = useMemo(() => {
    const _memos = description
      .split(',')
      .filter((memo) => isDeliveryTag(memo) || !isMedicatoinFollowupTag(memo));

    return _memos;
  }, [description]);

  return memos.length === 0 ? (
    <div>ー</div>
  ) : (
    <TagGroup>
      {memos.map((memo, idx) =>
        isDeliveryTag(memo) ? <DeliveryTag key={idx} text={memo} /> : <Tag key={idx}>{memo}</Tag>,
      )}
    </TagGroup>
  );
});

ReadOnlyMemo.displayName = 'ReadOnlyMemo';
