import { Result } from '@zxing/library';
import { useCallback, useState } from 'react';

import { QrCodePatient } from '~/state/reception/types';
import { toFullKatakana } from '~/utils/convert';

import { toYearFromWareki } from './wareki';

const splitNames = (name: string) => {
  const names = name.split(/\s/);

  return names;
};

const toBirthDate = (str: string) => {
  const year = toYearFromWareki(str) || new Date().getFullYear();

  switch (str.length) {
    // YYYYMMDD
    case 8:
      return `${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6, 8)}`;
    // GYYMMDD
    case 7:
      return `${year}-${str.slice(3, 5)}-${str.slice(5, 7)}`;
    // YYYYMM
    case 6:
      return `${str}-01`;
    // GYYMM
    case 5:
      return `${year}-${str.slice(3, 5)}-01`;
    // YYYY
    case 4:
      return `${str}-01-01`;
    // GYY
    case 3:
      return `${year}-01-01`;
    default:
      return undefined;
  }
};

export const useParseReadText = () => {
  const [error, setError] = useState<string | null>(null);

  const findPatient = useCallback((result: Result) => {
    setError(null);

    const lines = result.getText().split(/\r\n|\n/);
    if (!lines[0].includes('JAHIS')) {
      setError('患者情報の読み取りができませんでした。\n別のQRコードを読み込んでください。');
      return;
    }

    const _patient: QrCodePatient = {
      familyName: '',
      givenName: '',
      phoneticFamilyName: '',
      phoneticGivenName: '',
      birthDate: '',
      sex: 'male',
    };

    for (const line of lines) {
      const items = line.split(',');

      // 11: 患者氏名レコード
      if (items[0] === '11') {
        const name = splitNames(items[2]);
        const kana = splitNames(items[3]);

        _patient.familyName = name[0] || '';
        _patient.givenName = name[1] || '';
        _patient.phoneticFamilyName = kana[0] ? toFullKatakana(kana[0]) : '';
        _patient.phoneticGivenName = kana[1] ? toFullKatakana(kana[1]) : '';
      }

      // 12: 患者性別レコード
      if (items[0] === '12') {
        _patient.sex = items[1] === '2' ? 'female' : 'male';
      }

      // 13: 患者生年月日レコード
      if (items[0] === '13') {
        _patient.birthDate = toBirthDate(items[1]) || '';
      }
    }

    return _patient;
  }, []);
  const resetError = useCallback(() => setError(null), []);

  return { error, findPatient, resetError };
};
