import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { Box, Flex, Icon, Text, TextField, Tooltip } from '~/components/blocks';
import { Interview } from '~/components/partials';

import { CustomQuestionTitle } from '../CustomQuestionTitle';

type Props = {
  index: number;
  errors: string[] | string | undefined;
  value: number | '';
  onChange: (value: string) => void;
};

const Content = styled(Box)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.fontWeights.bold,
  }),
);

const CustomTextField = styled(TextField)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.default,
  }),
);

export const InputPiece = (props: Props) => {
  const { errors, onChange, value } = props;
  const theme = useTheme();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <Box>
      <Interview>
        <CustomQuestionTitle>
          <Text>荷物の中に「梱包物</Text>
          <Tooltip content={<Content>薬袋または薬袋を添付したもの</Content>}>
            <Flex alignItems="center">
              <Icon icon="hint" size="l" color="navy" />
            </Flex>
          </Tooltip>
          <Text>」はいくつありますか？</Text>
        </CustomQuestionTitle>
      </Interview>
      <Flex
        justifyContent="flex-start"
        width="240px"
        paddingBottom={theme.space.m}
        position="relative"
        alignItems="center"
      >
        <CustomTextField
          name="pieces"
          value={value ?? undefined}
          onChange={handleChange}
          error={typeof errors === 'string' && errors}
        />
        <Text fontWeight="bold" marginLeft={theme.space.m}>
          つ
        </Text>
      </Flex>
    </Box>
  );
};
