import { useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Dropdown, Flex, Icon, Text } from '~/components/blocks';
import { useB2cloud } from '~/hooks/use-b2cloud';
import { b2CloudDownloadModalState } from '~/state/reception/atoms';

export const Menu = () => {
  const theme = useTheme();
  const { enabledB2cloud } = useB2cloud();

  const handleDeliveryClick = useRecoilCallback(({ set }) => () => {
    set(b2CloudDownloadModalState, (_state) => ({ ..._state, isOpen: true }));
  });

  if (!enabledB2cloud) {
    return null;
  }

  return (
    <Dropdown icon="more">
      <Dropdown.Menu>
        <Dropdown.Item onClick={handleDeliveryClick}>
          <Flex>
            <Icon size="l" icon="delivery" marginRight={theme.space.s} />
            <Text size="s">ヤマト運輸 B2クラウドCSVダウンロード</Text>
          </Flex>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
