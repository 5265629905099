import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Drawer, Grid, Loader } from '~/components/blocks';
import { useMarkNotification } from '~/components/pages/Reception/CheckinEntryProfileDrawer/use-mark-notification';
import { useSwapDrawer } from '~/hooks/use-swap-drawer';
import { checkinEntryProfileDrawerState } from '~/state/reception/atoms';

import { FinishConfirmModal } from '././FinishConfirmModal';
import { CheckinEntryKarte } from './CheckinEntryKarte';
import { CheckinPatientProfile } from './CheckinEntryKarte/CheckinPatientProfile';
import { Footer } from './Footer';
import { NotificationConfirmModal } from './NotificationConfirmModal';
import { useFetchCheckinEntry } from './use-fetch-checkin_entry';

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const CheckinEntryProfileDrawer = () => {
  const {
    state: { isOpen },
    handleTransitionEnd,
  } = useSwapDrawer(checkinEntryProfileDrawerState, 'checkinEntryId');

  const { checkinEntry, loading } = useFetchCheckinEntry();
  useMarkNotification();

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(checkinEntryProfileDrawerState);
      },
    [],
  );

  return (
    <>
      <Drawer open={isOpen} onClose={handleClose} onTransitionEnd={handleTransitionEnd}>
        <Box position="relative" overflow="hidden" height="100%">
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <CheckinPatientProfile loading={loading} checkinEntry={checkinEntry} />
            </ProfileBox>
            {checkinEntry ? (
              <Box overflow="auto">
                <CheckinEntryKarte checkinEntry={checkinEntry} />
              </Box>
            ) : (
              <Loader inside open />
            )}
            <Footer checkinEntry={checkinEntry} />
          </Grid>
        </Box>
      </Drawer>
      <FinishConfirmModal />
      <NotificationConfirmModal />
    </>
  );
};
