import { useTheme } from '@emotion/react';
import React, { useCallback, useRef } from 'react';

import { Alert, Box, Button, Drawer, Grid } from '~/components/blocks';
import { DirectVisitorDetailFragment } from '~/graphql';

import { GuestPatientForm } from '../../../GuestPatientForm';
import { Fields } from '../../../GuestPatientForm/types';
import { useDefaultValues } from './use-default-values';
import { useEditGuestPatient } from './use-edit-guest_patient';

type Props = {
  guestPatient: DirectVisitorDetailFragment;
};

export const EditGuestPatientProfile = React.memo((props: Props) => {
  const theme = useTheme();
  const formRef = useRef<RefAttributeType<typeof GuestPatientForm>>(null);

  const defaultValues = useDefaultValues(props.guestPatient);
  const { error, isEditing, containerRef, edit } = useEditGuestPatient();

  const handleSend = useCallback(() => {
    if (!formRef.current) return;
    formRef.current.submitForm();
  }, []);
  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        await edit(values, props.guestPatient.id);
      } catch {
        // 何もしない
      }
    },
    [edit, props.guestPatient.id],
  );

  return (
    <Grid height="100%" overflow="auto" gridTemplateRows="1fr min-content">
      <Box height="100%" overflow="auto" padding={theme.space.l} ref={containerRef}>
        {error && (
          <Alert status="error" marginBottom={theme.space.l} whiteSpace="pre-wrap">
            {error}
          </Alert>
        )}
        <GuestPatientForm
          ref={formRef}
          disabled={isEditing}
          initialValues={defaultValues}
          onSubmit={handleSubmit}
        />
      </Box>
      <Drawer.Footer>
        <Button use="base" wide="fill" size="l" loading={isEditing} onClick={handleSend}>
          保存
        </Button>
      </Drawer.Footer>
    </Grid>
  );
});

EditGuestPatientProfile.displayName = 'EditGuestPatientProfile';
