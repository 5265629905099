import { useRecoilCallback } from 'recoil';

import { useRequestAppointmentMutation } from '~/graphql';
import { requestAppointmentModalState } from '~/state/reception/atoms';

type Props = {
  draftAppointmentId: string;
};

export const useRequestAppointment = (props: Props) => {
  const [request, { error, loading }] = useRequestAppointmentMutation();

  const handleRequest = useRecoilCallback(
    ({ reset }) =>
      async () => {
        try {
          await request({
            variables: {
              input: {
                draftAppointmentId: props.draftAppointmentId,
              },
            },
          });

          reset(requestAppointmentModalState);
        } catch {
          // 何もしない
        }
      },
    [props.draftAppointmentId, request],
  );

  return {
    loading,
    error,
    handleRequest,
  };
};
