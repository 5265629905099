import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormikProps } from 'formik';
import React from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';

import { Alert, Box, Button, Checkbox, Flex, Icon, Modal, Text } from '~/components/blocks';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';
import { RequestUberDeliveryModalState } from '~/state/reception/types';

import { PiecesContent } from '../PiecesContent';
import { Fields } from '../type';
import { AutoRadioInput } from './AutoRadioInput';
import { ContentItem } from './ContentItem';

type Props = {
  formik: FormikProps<Fields>;
  loading: boolean;
};

const CustomFlex = styled(Flex)(({ theme }) =>
  css({
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    marginRight: theme.space.m,
    marginBottom: theme.space.m,
    marginTop: theme.space.s,
  }),
);

const PROHIBITED_APP_NAME = 'production';

export const ConfirmContent = React.memo((props: Props) => {
  const { formik, loading } = props;
  const isProduction = process.env.appName === PROHIBITED_APP_NAME;
  const theme = useTheme();
  const [state, setState] = useRecoilState(requestUberDeliveryModalState);

  const handleAgreementChange = useRecoilCallback(
    ({ set }) =>
      () => {
        set(requestUberDeliveryModalState, (_state: RequestUberDeliveryModalState) => ({
          ..._state,
          isAgreement: !state.isAgreement,
        }));
      },
    [state.isAgreement],
  );

  return (
    <Box>
      <Modal.Body minHeight="360px">
        <Box marginBottom={theme.space.l}>
          <Text size="s">梱包情報を確認の上、「確定」ボタンを押してください</Text>
        </Box>
        {formik.values.pieces.map((value, idx) => (
          <PiecesContent key={idx} index={idx}>
            <ContentItem index={idx} value={value} />
          </PiecesContent>
        ))}
        {!isProduction && <AutoRadioInput />}
        <Alert status="info" withIcon={false}>
          <Text>集荷依頼後、配達員が到着するまでに梱包を完了してください</Text>
          <CustomFlex onClick={handleAgreementChange}>
            <Checkbox name="isAgreement" checked={state.isAgreement} />
            <Text ml={theme.space.m} size="m">
              理解しました
            </Text>
          </CustomFlex>
        </Alert>
      </Modal.Body>
      <Modal.Footer both>
        <Button
          size="xs"
          use="white"
          onClick={() => {
            setState((state) => ({ ...state, step: 'inputPieces' }));
          }}
        >
          <Icon icon="back-line" size="l" />
          <Text size="m">戻る</Text>
        </Button>
        <Button
          size="m"
          use="secondary"
          disabled={!state.isAgreement || loading}
          onClick={formik.submitForm}
        >
          確定
        </Button>
      </Modal.Footer>
    </Box>
  );
});

ConfirmContent.displayName = 'ConfirmContent';
