import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Modal } from '~/components/blocks';
import { requestAppointmentModalState } from '~/state/reception/atoms';

import { ConfirmFaxPanel } from './ConfirmFaxPanel';
import { MessageSendPanel } from './MessageSendPanel';
import { useFetchOrganizationName } from './use-fetch-organization-name';

type Props = {
  draftAppointmentId: string;
};

export const RequestAppointmentModal = (props: Props) => {
  const [state, setState] = useRecoilState(requestAppointmentModalState);
  const { organizationName } = useFetchOrganizationName({
    draftAppointmentId: props.draftAppointmentId,
  });

  const handleClose = useCallback(() => setState(() => ({ isOpen: false })), [setState]);
  const [isFaxConfirmed, setIsFaxConfirmed] = useState(false);
  const handleBack = useCallback(() => setIsFaxConfirmed(false), []);
  const handleNext = useCallback(() => setIsFaxConfirmed(true), []);

  useEffect(() => {
    if (!state.isOpen) {
      setIsFaxConfirmed(false);
    }
  }, [state.isOpen]);

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>日時指定依頼を送信</Modal.Header>
      {isFaxConfirmed ? (
        <MessageSendPanel
          onBack={handleBack}
          organizationName={organizationName}
          draftAppointmentId={props.draftAppointmentId}
        />
      ) : (
        <ConfirmFaxPanel onNext={handleNext} />
      )}
    </Modal>
  );
};
