import { useCallback, useState } from 'react';

import { GetCheckinEntryDetailDocument, useFinishCheckinEntryMutation } from '~/graphql';

export const useFinishCheckinEntry = (checkinEntryId: string) => {
  const [finishCheckinEntry, { loading: finishing }] = useFinishCheckinEntryMutation({
    refetchQueries: [{ query: GetCheckinEntryDetailDocument, variables: { id: checkinEntryId } }],
  });
  const [error, setError] = useState<string | null>(null);

  const finish = useCallback(async () => {
    if (!checkinEntryId) return;

    setError(null);

    try {
      await finishCheckinEntry({
        variables: {
          input: {
            checkinEntryId,
          },
        },
      });
    } catch (error) {
      setError(error?.message || 'エラーが発生しました');
      throw error;
    }
  }, [checkinEntryId, finishCheckinEntry]);

  return { finish, finishing, error };
};
