import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { useRecoilCallback } from 'recoil';

import { Box, Drawer, Grid, Loader } from '~/components/blocks';
import { DeleteMedicationFollowupMessageModal } from '~/components/partials';
import { useMarkNotification } from '~/components/partials/PatientProfileDrawer/use-mark-notification';
import { VisitorProfile } from '~/components/partials/VisitorProfile';
import { WebVisitorChargeModal } from '~/components/partials/WebVisitorChargeModal';
import { WebVisitorKarte } from '~/components/partials/WebVisitorKarte';
import { useSwapDrawer } from '~/hooks/use-swap-drawer';
import { webVisitorProfileDrawerState, webVisitorTabState } from '~/state/reception/atoms';

import { Footer } from './Footer';
import { NotificationConfirmModal } from './NotificationConfirmModal';
import { NotificationCongestionModal } from './NotificationCongestionModal';
import { useFetchAppointment } from './use-fetch-appointment';

const ProfileBox = styled(Box)(({ theme }) =>
  css({
    overflow: 'auto',
    padding: `${theme.space.l} ${theme.space.l} ${theme.space.m}`,
    [`@media ${theme.mediaQueries.tablet}`]: {
      padding: `${theme.space.l} ${theme.space.l} ${theme.space.xs}`,
    },
  }),
);

export const WebVisitorProfileDrawer = () => {
  const {
    state: { isOpen, appointmentId },
    handleTransitionEnd,
  } = useSwapDrawer(webVisitorProfileDrawerState, 'appointmentId');

  const { loading, appointment } = useFetchAppointment(isOpen, appointmentId);

  const handleClose = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(webVisitorProfileDrawerState);
        reset(webVisitorTabState);
      },
    [],
  );

  useMarkNotification(appointmentId);

  return (
    <>
      <Drawer open={isOpen} onClose={handleClose} onTransitionEnd={handleTransitionEnd}>
        <Box position="relative" overflow="hidden" height="100%">
          <Grid height="100%" gridTemplateRows="min-content 1fr min-content" overflow="hidden">
            <ProfileBox>
              <VisitorProfile loading={loading} visitor={appointment?.webVisitor} />
            </ProfileBox>
            {appointment ? (
              <>
                <Box overflow="auto">
                  <WebVisitorKarte appointment={appointment} />
                </Box>
                {appointment.webVisitor && <Footer appointment={appointment} />}
              </>
            ) : (
              <Loader inside open />
            )}
          </Grid>
        </Box>
      </Drawer>
      <WebVisitorChargeModal />
      <NotificationCongestionModal />
      <DeleteMedicationFollowupMessageModal />
      <NotificationConfirmModal />
    </>
  );
};
